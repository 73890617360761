@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&subset=latin-ext);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@charset "UTF-8";
.message-bubble-content, .input-file-progress, .file-name, .btn-file, .btn-help, .btn-link, .btn-link-on-text, .btn-default, .btn-danger, .btn-primary {
  border-radius: 3px;
}

.complementary-activities-collapse, .hours-footer, .school-record-collapse, .school-record-without-term .rt-thead.-headerGroups, .school-record .rt-thead.-headerGroups, .btn-hour, .dropdown-menu, .message-bubble-content, .grades-head-card, .card-border, .img-user, .year-calendar .p-datepicker, .p-calendar .p-datepicker, .stepper .step--icon, .basic-shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
}

.message:hover, .btn-danger:hover, .btn-primary:hover {
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.15);
}

.message-bubble-file:hover, .message-bubble-file, .message:hover, .message, .download-file, .delete-file, .file-name:hover .download-file, .file-name:hover .delete-file, .btn-file:hover, .btn-file, .btn-danger:hover, .btn-danger, .btn-primary:hover, .btn-primary, .btn {
  transition: all 0.3s;
}

@-webkit-keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Toastify__toast {
  border-radius: 7px !important;
  height: 75px !important;
  margin-top: -8px !important;
  box-shadow: 0px 5px 5px 0px rgba(17, 17, 17, 0.5) !important;
}

.Toastify__toast-body {
  margin-left: 10px !important;
}

.Toastify__close-button {
  color: #111 !important;
  background: #fafafa !important;
  margin-top: -8px !important;
  margin-right: -8px !important;
  height: 75px !important;
  width: 20px !important;
}

.Toastify__progress-bar {
  background-color: #fafafa !important;
  opacity: 0.7 !important;
}

.Toastify__toast--error {
  background: #d42333 !important;
}

.Toastify__toast--success {
  background: #03af56 !important;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: justify;
  line-height: 1.7rem;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #313131;
}

input {
  outline: none;
}

input:invalid {
  box-shadow: none;
}

h1 {
  margin: 0;
}

figure {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: 500;
}

hr {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  border: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
}

.hr-print {
  display: none !important;
}

.hr-desktop {
  display: block !important;
}

.hide {
  display: none;
}

.no-border {
  border: none !important;
}

.word-wrap-anywhere {
  word-wrap: anywhere;
}

@media (max-width: 1280px) {
  .rt-td .lesson-padding-left {
    padding-left: 0.5rem;
  }
}
.lesson-padding-left {
  padding-left: 1rem;
}

.container {
  max-width: 84rem;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 10px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

/* FLEX */
.content {
  display: flex;
  padding-left: 300px;
  transition: padding-left 0.3s;
  padding-top: 105px;
}
.content-area {
  padding: 2rem 3rem;
  flex: 1 1;
}
.content-right {
  border: 8px solid #f5f5f5;
  padding: 10px 20px;
  border-radius: 6px;
}

@media (max-width: 1200px) {
  .content {
    padding-left: 80px;
  }

  div p span.adjust--is-mobile p img {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .container {
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-top: 0;
  }

  .content-right {
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .content {
    padding-top: 100px;
    padding-left: 0;
  }
  .content-right {
    border: none;
  }
}
.mt-10 {
  margin-top: -10px !important;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
body {
    width: 210mm;
    height: 297mm;
  }
  html ::-webkit-scrollbar,
body ::-webkit-scrollbar {
    display: none;
  }

  .content {
    padding: 0 !important;
    margin: 0 !important;
  }

  .container {
    padding: 0 !important;
    margin: 0 !important;
  }

  .content-right {
    padding: 0 2rem !important;
    margin: 0 !important;
  }

  .show-on-print {
    display: block !important;
  }

  .page-title h3 {
    padding: 1rem !important;
    color: #111 !important;
  }

  .teaching-plan {
    padding-left: 1rem !important;
    margin-top: -20px !important;
  }

  .teaching-plan-text {
    margin-top: -10px !important;
  }

  .filter-print {
    display: none !important;
  }

  .d-flex-print {
    display: flex !important;
  }
  .d-flex-print .flex-50-print {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
    flex-basis: calc(50% - 1em) !important;
  }
  .d-flex-print .flex-35-print {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
    flex-basis: calc(35% - 1em) !important;
  }
  .d-flex-print .flex-15-print {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
    flex-basis: calc(15% - 1em) !important;
  }

  .mt-5-print {
    margin-top: -50px !important;
  }

  .mt-4-print {
    margin-top: -30px !important;
  }
  .mt-4-print .mb-print {
    margin-bottom: 15px !important;
  }

  .mb-0-print {
    margin-bottom: 0px !important;
  }

  .mb-1-print {
    margin-bottom: 1rem !important;
  }

  .mt-10-print {
    margin-top: -10px !important;
  }

  .mt-0-print {
    margin-top: 0px !important;
  }

  .mt-2-print {
    margin-top: -20px !important;
  }

  .ml-20-print {
    margin-left: 20px !important;
  }

  .mr-3-print {
    margin-right: 3rem !important;
  }

  .mr-7-print {
    margin-right: 70px !important;
  }

  .flex-1-print {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
    flex-basis: calc(50% - 1em) !important;
  }

  .flex-25-print {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
    flex-basis: calc(25% - 1em) !important;
  }

  .flex-3-print {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
    flex-basis: calc(40% - 1em) !important;
  }

  .flex-4-print {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
    flex-basis: calc(20% - 1em) !important;
  }

  .width-270 {
    width: 270px !important;
  }

  .hr-print {
    display: block !important;
  }

  .hr-desktop {
    display: none !important;
  }

  .card-deck {
    display: block !important;
  }
  .card-deck .card-100 {
    display: block !important;
    border: none !important;
    border-radius: none !important;
    box-shadow: none !important;
    margin: 0 !important;
    margin-top: -30px !important;
    page-break-inside: avoid;
  }
  .card-deck .card-100 .brown {
    color: #5f5f5f !important;
  }
  .card-deck .card-100 .info {
    color: #efebd8 !important;
  }
  .card-deck .card-100 .card-head {
    margin-top: 15px !important;
    color: #5f5f5f !important;
    font-weight: bolder !important;
  }
  .card-deck .h-125px {
    height: 90px !important;
  }
  .card-deck .p-2 {
    padding: 0 !important;
  }

  .remove-on-print {
    display: none !important;
  }

  .pagination {
    display: none !important;
  }

  .p-2 {
    padding: 0 !important;
  }

  .mr-2 .brown {
    color: #5f5f5f !important;
  }

  .input-type-style .dropdown__indicators {
    display: none !important;
  }
  .input-type-style .dropdown-style {
    background: none !important;
  }

  .text-area-style {
    background: none !important;
  }

  .input-label-style {
    font-weight: 600 !important;
  }
}
.show-on-print {
  display: none;
}

.show-on-print {
  display: none;
}

.justify-end {
  justify-content: end;
}

.people-img {
  border-radius: 50%;
  box-shadow: 0 4px 6px 0 rgba(17, 17, 17, 0.1);
  width: 6em;
  height: 6em;
  background: #fff;
}

.people-button {
  margin: 2rem 0;
  display: block;
  color: #039f97;
  text-decoration: underline;
}

.text-underline {
  text-decoration: underline;
}

.text-capitalize {
  text-transform: capitalize;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.bg-transparent {
  background-color: transparent;
}

.bg-dim {
  background-color: rgba(17, 17, 17, 0.6);
}

.bg-gray {
  background-color: rgba(17, 17, 17, 0.1);
}

.bg-info {
  background-color: #efebd8;
}

.bg-white {
  background-color: #fff;
}

.bg-white-darker {
  background-color: #fafafa;
}

.bg-silver,
.h-bg-silver:hover {
  background-color: #f8f8f8;
  background-size: 100%;
  box-shadow: none !important;
}

.bg-blue {
  background-color: #208fdc;
}

.bg-blue-grad {
  background: linear-gradient(90deg, #208fdc 0, #0090ce);
}

.bg-red-grad {
  background: linear-gradient(90deg, #ff5046 0, #f9838c);
}

.bg-yellow-grad {
  background: linear-gradient(90deg, #e2d7a6 0, #ff891c);
}

.bg-green-grad {
  background: linear-gradient(90deg, #00a397 0, #039f97);
}

.bg-brown-grad {
  background: linear-gradient(90deg, #7d6c2f 0, #a7985f);
}

.bg-green {
  background-color: #039f97;
}

.bg-red {
  background-color: #ff5046;
}

.bg-yellow {
  background-color: #e2d7a6;
}

.bg-gray-light {
  background-color: #f5f5f5 !important;
}

.border-yellow {
  border-color: #e2d7a6;
}

.border-red {
  border-color: #ff5046;
}

.border-green {
  border-color: #039f97;
}

.border-blue {
  border-color: #208fdc;
}

.border-info {
  border-color: #efebd8 !important;
}

.border-white-50 {
  border-color: rgba(255, 255, 255, 0.5);
}

.primary {
  color: #039f97;
}

.green {
  color: #039f97;
}

.icon-color {
  color: #c7c7c7;
}

.info {
  color: #efebd8;
}

.success {
  color: #74b777;
}

.disabled {
  color: #585858;
}

.danger {
  color: #b77474;
}

.yellow {
  color: #e2d7a6;
}

.silver-dark {
  color: #5f5f5f;
}

.gray-dark {
  color: #585858;
}

.silver-light {
  color: #aaaaaa;
}

.blue {
  color: #208fdc;
}

.dim {
  color: rgba(17, 17, 17, 0.6);
}

.white {
  color: #fff;
}

.red {
  color: #ff5046 !important;
}

.brown {
  color: #a7985f !important;
}

.text-black {
  color: #707070 !important;
}

.secondary {
  color: #a7985f;
}

.medium-silver {
  color: #aaa9a9;
}

.fill-red {
  fill: red !important;
}

.spacement {
  padding: 5rem;
}

.font-color {
  color: #313131;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-30 {
  font-size: 30px;
}

@media (max-width: 768px) {
  .font-12-mobile {
    font-size: 12px !important;
  }

  .font-13-mobile {
    font-size: 13px !important;
  }

  .font-14-mobile {
    font-size: 14px !important;
  }

  .font-16-mobile {
    font-size: 16px !important;
  }

  .font-18-mobile {
    font-size: 18px !important;
  }

  .font-20 {
    font-size: 20px;
  }

  .font-22 {
    font-size: 22px;
  }

  .font-30 {
    font-size: 30px;
  }
}
.font-weight-200 {
  font-weight: 200;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  transition: all 0.3s;
  opacity: 0.8;
}

.text-status {
  margin-bottom: 0;
}
.text-status span {
  margin-left: 7px;
  font-weight: 600;
  font-size: 13px;
  color: #585858;
}

.text-data {
  font-size: 12px;
  padding-left: 21px;
  font-weight: 300;
}

.label--title {
  font-weight: 600;
}
.label--text {
  font-weight: 500;
}
.label--secondary {
  color: #a7985f;
  font-weight: 600;
  align-self: center;
  padding-right: 1rem;
}

.resultados-label {
  text-transform: uppercase !important;
  font-size: 12px;
}

.right-animation-enter {
  transform: translateX(100%);
}

.right-animation-enter-active {
  transform: translateX(0%);
  transition: all 0.25s ease;
}

.right-animation-exit {
  transform: translateX(0%);
}

.right-animation-exit-active {
  transform: translateX(100%);
  transition: all 0.25s ease;
}

.left-animation-enter {
  transform: translateX(-100%);
}

.left-animation-enter-active {
  transform: translateX(0%);
  transition: all 0.25s ease;
}

.left-animation-exit {
  transform: translateX(0%);
}

.left-animation-exit-active {
  transform: translateX(-100%);
  transition: all 0.25s ease;
}

.expand-animation-enter {
  transform: scale(0);
  opacity: 0;
  transform-origin: bottom right;
}

.expand-animation-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: all 0.25s ease-in;
}

.expand-animation-exit {
  transform: scale(1);
  opacity: 1;
  transform-origin: bottom right;
}

.expand-animation-exit-active {
  transform: scale(0);
  opacity: 0;
  transition: all 0.25s ease-out;
}

.chevron {
  -webkit-animation: all 2s;
          animation: all 2s;
}

.img-summary {
  max-width: 8rem;
  height: auto;
}

label {
  margin-bottom: 0;
}

.tag-null {
  background-color: #eee;
  padding: 1rem;
  text-align: center;
  border-radius: 3px;
  font-weight: 200;
  margin-bottom: 1rem;
}

.one-page-only {
  padding: 2rem;
  border: 1rem solid #eee;
  margin-top: 2rem;
}

.title-button-page {
  display: flex;
  justify-content: space-between;
}

.helper {
  margin-left: 4.5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index-10 {
  z-index: 10;
}

pre {
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

.bg-errors {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 112px);
  background-image: linear-gradient(#039f97, #fff);
}

.default-line {
  margin: 5px;
  border-color: #dddddd;
}

.no-icon {
  width: 20px;
  height: 20px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.cursor-pointer:hover {
  cursor: pointer;
}

body .p-link:focus {
  box-shadow: none;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.width-30 {
  width: 30% !important;
}

.width-70 {
  width: 70% !important;
}

.mh-100 {
  min-height: 100px;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: 0.5rem;
}

.right-2 {
  right: 1rem;
}

.right-3 {
  right: 2rem;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.hidden {
  display: none;
}

.break-line {
  overflow: visible;
  white-space: normal;
}

.d-show-mobile {
  display: none;
}

.d-show-desktop {
  display: block;
}

@media (max-width: 768px) {
  .d-show-mobile {
    display: block;
  }

  .d-show-desktop {
    display: none;
  }
}
.h-125px {
  height: 125px;
}

.bold {
  font-weight: 800;
}

.semi-bold {
  font-weight: 600;
}

@media print {
  .navigation-logo {
    margin: 1rem !important;
  }

  section {
    page-break-inside: avoid;
    page-break-after: always;
  }

  .card-deck {
    page-break-inside: avoid;
  }
}
.green-border {
  border-color: #d1ded2 !important;
}

.dynamic-background:nth-child(even) {
  background: #f5f5f5;
}

.non-list-style {
  list-style-type: none;
}

.input-error {
  border-color: #b77474;
  border-width: 1px;
  border-style: solid;
}

.content-left {
  text-align: left;
}

.gap-5 {
  grid-gap: 5px;
  gap: 5px;
}

.gap-10 {
  grid-gap: 10px;
  gap: 10px;
}

.gap-20 {
  grid-gap: 20px;
  gap: 20px;
}

.gap-30 {
  grid-gap: 30px;
  gap: 30px;
}

.d-block {
  display: block;
}

.flex-basic-50 {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(50% - 1rem);
}
.flex-basic-50.input-type-style {
  max-width: inherit;
}
.flex-basic-50 .input-type-style {
  max-width: inherit;
}

@media (max-width: 480px) {
  .flex-basic-50 {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(100% - 1rem);
  }
}
@media (max-width: 720px) {
  .no-justify-mobile {
    justify-content: initial !important;
  }
}
.d-flex,
.d-flex-responsive {
  display: flex;
}
.d-flex.align-self-center,
.d-flex-responsive.align-self-center {
  align-self: center;
}
.d-flex.align-self-end,
.d-flex-responsive.align-self-end {
  align-self: flex-end;
}
.d-flex.justify-space-between,
.d-flex-responsive.justify-space-between {
  justify-content: space-between;
}
.d-flex.justify-space-around,
.d-flex-responsive.justify-space-around {
  justify-content: space-around;
}
.d-flex.justify-center,
.d-flex-responsive.justify-center {
  justify-content: center;
}
.d-flex.justify-flex-start,
.d-flex-responsive.justify-flex-start {
  justify-content: flex-start;
}
.d-flex.justify-flex-end,
.d-flex-responsive.justify-flex-end {
  justify-content: flex-end;
}
.d-flex.align-items-center,
.d-flex-responsive.align-items-center {
  align-items: center;
}
.d-flex.align-items-flex-start,
.d-flex-responsive.align-items-flex-start {
  align-items: flex-start;
}
.d-flex.align-items-flex-end,
.d-flex-responsive.align-items-flex-end {
  align-items: flex-end;
}
.d-flex.flex-direction-column,
.d-flex-responsive.flex-direction-column {
  flex-direction: column;
}
.d-flex-start,
.d-flex-responsive-start {
  display: flex;
  align-items: flex-start;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-start {
  align-self: flex-start;
}

.flex-end {
  align-self: flex-end;
}

.center {
  align-self: center;
}

.baseline {
  align-self: baseline;
}

.stretch {
  align-self: stretch;
}

.flex-1 {
  flex: 1 1 auto;
}

.flex-grow {
  flex-grow: 1 !important;
}

.flex-2 {
  flex: 2 1 auto;
}

.flex-3 {
  flex: 3 1 auto;
}

.flex-4 {
  flex: 4 1 auto;
}

.flex-5 {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(20% - 1rem);
}

.background-grid {
  height: 50px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
@media screen and (max-width: 768px) {
  .flex-direction-column-mobile {
    flex-direction: column;
  }

  .align-items-flex-start-mobile {
    align-items: flex-start !important;
  }

  .justify-content-flex-start-mobile {
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 768px) {
  .d-flex-responsive {
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .d-block-mobile {
    display: block;
  }
}
@media (min-width: 768px) {
  .flex-25 {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(25% - 1em);
  }
}
@media (max-width: 768px) {
  .flex-25 {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 1em);
  }
}
@media (max-width: 480px) {
  .flex-25 {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(100% - 1em);
  }
}
.unlimited-flex {
  flex: auto;
}

.flex-66 {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(66% - 1em);
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-75 {
  width: 75%;
}

.w-70 {
  width: 70%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.w-10 {
  width: 10%;
}

.w-0 {
  width: 0;
}

@media (max-width: 768px) {
  .w-100-mobile {
    width: 100%;
  }
}
.h-100 {
  height: 100%;
}

.max-width-100 {
  max-width: 100px;
}

.max-width-150 {
  max-width: 150px;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-250 {
  max-width: 250px;
}

.max-width-650 {
  max-width: 650px;
}

.min-width-100 {
  min-width: 100px;
}

.min-width-150 {
  min-width: 150px;
}

.min-width-200 {
  min-width: 200px;
}

.min-width-250 {
  min-width: 250px;
}

.width-50 {
  width: 50px;
}

.width-100 {
  width: 100px;
}

.width-150 {
  width: 150px;
}

.width-200 {
  width: 200px;
}

.width-250 {
  width: 250px;
}

.width-270 {
  width: 270px;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-1 {
  margin: 0.5rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.m-2 {
  margin: 1rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mr-2 {
  margin-right: 1rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-2-5 {
  margin-bottom: 2.5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.m-3 {
  margin: 2rem;
}

.mt-3 {
  margin-top: 2rem !important;
}

.mr-3 {
  margin-right: 2rem !important;
}

.mb-3 {
  margin-bottom: 2rem !important;
}

.ml-3 {
  margin-left: 2rem;
}

.mx-3 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m-4 {
  margin: 4rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.ml-4 {
  margin-left: 4rem;
}

.mx-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.my-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.m-5 {
  margin: 5rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mr-5 {
  margin-right: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mx-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.my-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mxn-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mxn-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mxn-3 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mxn-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mxn-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-1 {
  padding: 0.5rem;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pr-1 {
  padding-right: 0.5rem;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pl-1 {
  padding-left: 0.5rem;
}

.px-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.p-2 {
  padding: 1rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pr-2 {
  padding-right: 1rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pl-2 {
  padding-left: 1rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-3 {
  padding: 2rem;
}

.pt-3 {
  padding-top: 2rem;
}

.pr-3 {
  padding-right: 2rem;
}

.pb-3 {
  padding-bottom: 2rem;
}

.pl-3 {
  padding-left: 2rem;
}

.px-3 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-3 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-4 {
  padding: 4rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pr-4 {
  padding-right: 4rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pl-4 {
  padding-left: 4rem;
}

.px-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-5 {
  padding: 5rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pr-5 {
  padding-right: 5rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pl-5 {
  padding-left: 5rem;
}

.px-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pxn-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.pxn-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pxn-3 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pxn-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.pxn-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.pl-auto {
  padding-left: auto;
}

.pr-auto {
  padding-right: auto;
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}

@media (max-width: 768px) {
  .mt-2-mobile {
    margin-top: 1rem;
  }
}
@media (max-width: 820px) {
  .ml-0--is-mobile {
    margin-left: 0px;
  }
}
.word-break {
  word-break: break-word;
}

.ba2b28a5-99b5-481a-a3d6-27eb24280e6e {
  fill: #86c0c1;
}

.e9c1bacc-187d-4d99-a102-5e2203cc1ecf {
  fill: #fff;
}

.a4940688-da3f-4b25-8f6b-0b6aaea6e669,
.bc8a55b4-314b-4f97-839f-a6d88c5ec426,
.ef26a2b0-8edb-4ef0-b756-68866bf8f4d4 {
  fill: none;
}

.a4940688-da3f-4b25-8f6b-0b6aaea6e669 {
  stroke: #5a5a5a;
  opacity: 0.43;
}

.a4940688-da3f-4b25-8f6b-0b6aaea6e669,
.bc8a55b4-314b-4f97-839f-a6d88c5ec426 {
  isolation: isolate;
}

.e63b201e-04da-4689-ab5c-c47e65031a99 {
  fill: #ededed;
}

.b613ad63-8c32-4e8b-9d2a-db998bd7653b {
  fill: #707070;
}

.bc8a55b4-314b-4f97-839f-a6d88c5ec426,
.ef26a2b0-8edb-4ef0-b756-68866bf8f4d4 {
  stroke: #707070;
}

.bc8a55b4-314b-4f97-839f-a6d88c5ec426 {
  opacity: 0.46;
}

.a8d79614-2f1d-4a58-851b-23c3954672cd {
  fill: #efebd8;
}

.bba96737-43b5-408d-9c89-3e3e0bb11990 {
  fill: url(#b1da634d-805d-4bfc-ad3d-921e53a484b2);
}

.a9b8aa98-6925-49b4-8c40-111273b0b47a {
  fill: url(#b19c14c3-4c47-4215-babf-e22c7acf267e);
}

.a75657b5-bc3f-42dc-af17-1659c41383d1 {
  fill: #efefef;
}

.bcc50b28-125b-49a1-8b0f-f70ce408cca4 {
  fill: #ff8137;
}

.b68d1de6-821d-4e1a-b970-02a79faa8c62 {
  fill: #ff6739;
}

.svg-size {
  width: 300px;
}

.a8b65191-7ca1-4a66-bfd7-1ed79ee9825e {
  fill: #86c0c1;
}

.b290c448-5abf-4563-ae39-99a1b2808895 {
  fill: #fff;
}

.b23dc51d-14cb-4e6c-9f48-20291fa0f0e9 {
  fill: #ededed;
}

.e381f779-b0d3-4f6a-96f4-9dd593883e14 {
  fill: #707070;
}

.b6feb140-3c93-45a1-a06d-1f7f8a8a0312,
.f45d2b9c-2a05-47cb-9753-8d836a341fe8 {
  fill: none;
  stroke: #707070;
}

.b6feb140-3c93-45a1-a06d-1f7f8a8a0312 {
  opacity: 0.46;
  isolation: isolate;
}

.b5c4b21f-f4e0-40bb-a465-9e38ec4010d8 {
  fill: #efebd8;
}

.ee89c055-7b23-4951-bec5-99a30fe95806 {
  fill: url(#ff5dc906-4943-4c29-b1f8-39452318255c);
}

.a342a1df-5dde-4b04-94ab-6bfa04075f6b {
  fill: url(#a703456b-2dd0-4639-b2af-c085aa307c13);
}

.a11f7ba2-ef7a-4225-9801-2f6ed262a94f {
  fill: #efefef;
}

.b1225692-2c0a-47cb-8cce-29e8342b46cc {
  fill: #ff8137;
}

.f2e94514-5050-4aba-b341-477f1d6fe949 {
  fill: #ff6739;
}

.a41c562b-184a-4691-ad76-fa98a4c45daf {
  fill: #039f97;
}

.Toastify__toast-container--top-right {
  top: 8em !important;
}

@media (max-width: 480px) {
  .Toastify__toast-container {
    padding: 10px !important;
  }
}

.Toastify__toast {
  font-weight: 600;
}

.Toastify__toast--error {
  background-color: #b77474 !important;
}

.Toastify__toast--success {
  background-color: #74b777 !important;
}

.Toastify__close-button {
  margin-right: 0 !important;
  background: inherit !important;
  color: inherit !important;
  opacity: 1 !important;
}

.d-grid {
  display: grid;
}

.grid-gap-2rem {
  grid-gap: 2rem;
}

.chat-bubble {
  background-color: #efefef;
  color: #585858;
  padding: 10px 15px;
  border-radius: 3px;
  width: 100%;
}
.chat-bubble.is-author {
  background-color: #dcf8c6;
}

.nav-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .nav-mobile {
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #039f97;
    box-shadow: 0px 9px 25px rgba(0, 0, 0, 0.1);
    z-index: 10;
    padding: 15px 2rem;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
  }
  .nav-mobile .menu-list {
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 0;
    list-style: none;
    background: #fff;
    height: 100%;
    width: 0;
    padding: 0;
    transition: width 0.2s;
    box-shadow: 0px 9px 25px rgba(0, 0, 0, 0.1);
  }
  .nav-mobile .menu-list.open {
    width: 300px;
    transition: width 0.2s;
  }
  .nav-mobile .menu-list .close-sidebar {
    position: fixed;
    width: 300px;
    bottom: 0;
    min-height: 40px;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    background: #fff;
    border-top: 1px solid #eee;
  }
  .nav-mobile .menu-list .menu-elements {
    padding-top: 20px;
    padding-left: 2rem;
  }
  .nav-mobile .menu-list .menu-elements .menu-element {
    padding: 15px 0;
    white-space: nowrap;
  }
  .nav-mobile .menu-list .menu-elements .menu-element button {
    background: transparent;
    border: none;
    font-size: 14px;
    outline: none;
  }
  .nav-mobile .menu-list .menu-elements .menu-element:last-child {
    border-bottom: none;
  }
  .nav-mobile .menu-list .menu-elements .menu-element .active-menu {
    font-weight: 400;
  }
  .nav-mobile .menu-list .menu-elements .menu-element .active-menu .menu-icon {
    color: #fff;
    transition: 0.2s;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .nav-mobile .menu-list .menu-elements .menu-element .active-menu .menu-icon-border {
    background: #039f97;
  }
  .nav-mobile .menu-list .menu-elements .menu-element .menu-icon-border {
    border: 1px solid #039f97;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
  .nav-mobile .menu-list .menu-elements .menu-element .menu-icon {
    color: #039f97;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
    transition: 0.2s;
  }
  .nav-mobile .menu-list .menu-elements .menu-element p {
    font-size: 14px;
    font-weight: 500;
    color: #5f5f5f;
    margin: 0;
  }
  .nav-mobile .menu-list .menu-elements .menu-element .menu-list-internal {
    padding-top: 15px;
    list-style-type: none;
    padding-left: 43px;
  }
  .nav-mobile .menu-list .menu-elements .menu-element .menu-list-internal li {
    font-size: 14px;
    padding-bottom: 10px;
    white-space: normal;
  }
  .nav-mobile .menu-list .menu-elements .menu-element .menu-list-internal li .active-link {
    color: #a7985f;
  }
  .nav-mobile .menu-list .menu-elements .menu-element .menu-list-internal li a {
    color: #5f5f5f;
  }
  .nav-mobile .menu-list .mobile-elements {
    margin-bottom: 5rem;
    padding-left: 1rem;
    margin-right: 1rem;
  }
}
.fill-white {
  fill: #fff;
}

.fill-black {
  fill: #111;
}

.center-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 30px;
}

.notification-description {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
}

.step-header-base {
  padding: 2rem 1rem;
  display: flex;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 0px 20px 0 rgba(17, 17, 17, 0.1);
}
.step-header-base .step-header-border {
  height: 3em;
  border-right: 1px solid #dddddd;
  margin-right: 1rem;
}
.step-header-base .step-header-title {
  flex: 1 1 auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  line-height: 1.25;
}
.step-header-base .step-header-title h3 {
  color: #039f97;
  font-size: 1rem;
  font-weight: bold;
}
.step-header-base .step-header-title h4 {
  color: rgba(17, 17, 17, 0.6);
  margin-top: 0.3rem;
  font-weight: 400;
  font-size: 0.9rem;
}

.ball-style {
  font-weight: bold;
  text-align: center;
  margin-right: 1rem;
  border-radius: 50%;
  box-shadow: 0 4px 6px 0 rgba(17, 17, 17, 0.1);
  height: 4em;
  width: 4em;
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.ball-style h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

.small {
  width: 3rem;
  height: 3rem;
  padding: 0;
  margin: 1rem;
}

.extra-small {
  width: 2.3rem;
  height: 2.3rem;
  padding: 0;
  margin: 1rem;
}

.step-header-status {
  min-width: 200px;
}
.step-header-status .step-status {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: left;
}
.step-header-status .step-status label {
  color: #5f5f5f;
  font-weight: 700;
}

@media only screen and (max-width: 540px) {
  .step-header-status {
    display: none;
  }
}
.btn-danger, .btn-primary {
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.btn {
  -webkit-appearance: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 13px;
  height: auto;
  line-height: 1.125rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  border-radius: 100px;
}
.btn:last-child {
  margin-right: 0;
}
.btn-w50 {
  max-width: 50%;
}
.btn:focus {
  outline: none;
}
.btn-small {
  font-size: 12px;
  padding: 5px 10px;
  font-weight: 600;
}
.btn-medium {
  font-size: 14px;
}
.btn-large {
  font-size: 16px;
}
.btn-primary {
  background-color: #039f97;
  border: 2px solid #039f97;
  font-weight: 600;
}
.btn-primary:hover {
  background-color: #3f928b;
  border-color: transparent;
  text-decoration: none;
  color: #fff;
}
.btn-primary:active {
  background-color: #3f928b;
  border-color: transparent;
  text-decoration: none;
}
.btn-primary:disabled {
  background-color: rgba(17, 17, 17, 0.1);
  opacity: 0.5;
  font-weight: 600;
  border-color: #aaaaaa;
}
.btn-primary:disabled:hover {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  box-shadow: #585858;
  cursor: not-allowed;
}
.btn-danger {
  background-color: #e6646e;
  border: 2px solid #e6646e;
}
.btn-danger:hover {
  background-color: #c84f59;
  border-color: transparent;
  text-decoration: none;
}
.btn-collapse {
  padding: 0;
  margin: 0;
  height: 20px;
  margin: 0 0.5rem;
  border: none;
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  color: #707070;
}
.btn-add {
  font-size: 12px;
  background: none;
  border: none;
}
.btn-add span {
  color: #585858;
}
.btn-add svg {
  align-self: center;
  font-size: 17px;
  margin-right: 0.5rem;
}
.btn-add svg:hover {
  color: #3f928b;
}
.btn-add:hover {
  color: #111;
}
.btn-default {
  background: #fff;
  border: 2px solid #039f97;
  color: #039f97;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.btn-default:hover {
  border-color: #3f928b;
  color: #3f928b;
}
.btn-default:active {
  border-color: #3f928b;
  color: #3f928b;
}
.btn-default:disabled {
  border-color: #aaaaaa;
  color: #aaaaaa;
  background-color: rgba(17, 17, 17, 0.1);
}
.btn-default:disabled:hover {
  border-color: #aaaaaa;
  box-shadow: none;
  cursor: not-allowed;
}
.btn-link-on-text {
  font-size: 14px;
  padding: 0;
  margin: 0;
  margin-left: 8px;
  background: transparent;
  border: none;
  color: #039f97;
  text-decoration: underline;
}
.btn-link-on-text:hover {
  color: #7d7140;
}
.btn-link-on-text:active {
  color: #7d7140;
}
.btn-link-on-text:disabled {
  border-color: #aaaaaa;
  color: #aaaaaa;
  background-color: rgba(17, 17, 17, 0.1);
}
.btn-link-on-text:disabled:hover {
  border-color: #aaaaaa;
  box-shadow: none;
  cursor: not-allowed;
}
.btn-link {
  background: transparent;
  border: none;
  color: #039f97;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: underline;
}
.btn-link:hover {
  color: #7d7140;
  background: transparent;
  border: none;
  box-shadow: none;
  text-decoration: underline;
}
.btn-link:active {
  color: #7d7140;
}
.btn-link:disabled {
  color: #aaaaaa;
  border-color: transparent;
  background-color: transparent;
}
.btn-link:disabled:hover {
  border-color: #aaaaaa;
  box-shadow: none;
  background: transparent;
  cursor: not-allowed;
}
.btn-link.white {
  color: #fff;
}
.btn-link.white:hover {
  color: #aaaaaa;
}
.btn-help {
  background: transparent;
  border-color: transparent;
  color: #aaaaaa;
  font-weight: 600;
  text-decoration: underline;
  padding: 0;
  font-size: 10px;
}
.btn-help:hover {
  color: #5f5f5f;
}
.btn-help:active {
  color: #5f5f5f;
}
.btn-secondary {
  background-color: white;
  border: none;
  color: #5f5f5f;
  text-transform: none;
  font-weight: 600;
  border-radius: 6px;
  min-width: 100px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 16px;
}
.btn-secondary.active, .btn-secondary:active {
  background-color: #eaeaea;
}
.btn-black {
  background-color: #5f5f5f;
  border-radius: 3px;
  color: #fff;
  text-transform: uppercase;
}
.btn-black:hover {
  background-color: #111;
  border-color: transparent;
  box-shadow: inset 0 0 0 20rem rgba(17, 17, 17, 0.1);
  text-decoration: none;
}
.btn-black:active {
  background-color: #039f97;
  border-color: transparent;
  box-shadow: inset 0 0 0 20rem rgba(0, 0, 0, 0.125), inset 0 3px 4px 0 rgba(0, 0, 0, 0.25), 0 0 1px rgba(0, 0, 0, 0.125);
  text-decoration: none;
}
.btn-black:focus {
  box-shadow: inset 0 0 0 20rem rgba(17, 17, 17, 0.1);
  outline: none;
}
.btn-black:disabled {
  background-color: rgba(17, 17, 17, 0.1);
  border-color: #aaaaaa;
}
.btn-black:disabled:hover {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  box-shadow: none;
  cursor: not-allowed;
}
.btn.disabled {
  border-color: #aaaaaa;
  color: #aaaaaa;
  background-color: rgba(17, 17, 17, 0.1);
  opacity: 0.5;
  font-weight: 600;
}
.btn.disabled:hover {
  background-color: rgba(17, 17, 17, 0.1);
  border-color: #aaaaaa;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-actions {
  display: flex;
  justify-content: space-between;
}

.btn-rounded {
  border-radius: 50%;
  border: 1px solid #039f97;
  background: linear-gradient(90deg, #00a397 0, #039f97);
  display: flex;
  padding: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.btn-rounded:hover {
  transition: all 0.3s;
  text-decoration: none !important;
}
.btn-rounded-white {
  border: 2px solid #eaeaea;
  background: white;
  text-decoration: none !important;
  color: #585858;
}
.btn-rounded-white:hover {
  box-shadow: none;
}

.btn-rounded-red {
  border-radius: 50%;
  border: 1px solid #ff5046;
  background: #ff5046;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-rounded-red:hover {
  cursor: pointer;
  text-decoration: none;
}

.btn-none {
  border: none;
  position: relative;
  background: transparent;
}
.btn-none:hover {
  cursor: pointer;
  opacity: 0.5;
}
.btn-none:focus {
  outline: none;
}

.btn-file {
  padding: 0.5rem 1rem;
  background: #f5f5f5;
}
.btn-file:hover {
  background: rgba(17, 17, 17, 0.1);
}
.btn-file span {
  color: #585858;
  font-weight: 300;
}

@media (max-width: 768px) {
  .btn-responsive-100 {
    width: 100% !important;
    text-align: center;
  }
}
@media print {
  .btn {
    display: none;
  }
}
.back-button {
  padding: 0;
  font-size: 10px;
}

.card-btn {
  border: none;
  background: transparent;
  font-family: "Open Sans", sans-serif;
  color: #707070;
}
.card-btn:hover {
  cursor: pointer;
}
.card-btn:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.cancel-button {
  position: absolute;
  top: 4.5rem;
  right: 2.5rem;
}

@media (max-width: 480px) {
  .cancel-button {
    top: 3.3rem;
    right: 1.3rem;
  }
}
.btn-group {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 500px) {
  .btn-group {
    flex-direction: column-reverse;
  }
  .btn-group .btn {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    margin-left: 0;
  }
}
.filter-button {
  box-shadow: -2px 5px 10px rgba(0, 0, 0, 0.1);
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.input-icon {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.input-tooltip {
  width: 600px;
  text-align: left;
  color: #585858 !important;
  background-color: #f5f5f5 !important;
}
.input-tooltip:after {
  border-right-color: #f5f5f5 !important;
}

.input-required:after {
  content: "*";
}

.input-type-style {
  margin-top: 1rem;
  position: relative;
}
.input-type-style.input-file {
  margin-top: 0;
}
.input-type-style .input-style,
.input-type-style .input-error {
  width: 100%;
}
.input-type-style .input-style textarea,
.input-type-style .input-error textarea {
  min-height: 38px;
  width: 100%;
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
  border: none;
  box-shadow: none;
  outline-offset: inherit;
  outline: none;
  resize: none;
  background: transparent;
  font-family: "Open Sans", sans-serif;
}
.input-type-style .input-style input,
.input-type-style .input-error input {
  min-height: 38px;
  width: 100%;
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
}
.input-type-style .input-style input::-webkit-input-placeholder, .input-type-style .input-error input::-webkit-input-placeholder {
  color: #808080;
}
.input-type-style .input-style input::-ms-input-placeholder, .input-type-style .input-error input::-ms-input-placeholder {
  color: #808080;
}
.input-type-style .input-style input::placeholder,
.input-type-style .input-error input::placeholder {
  color: #808080;
}
.input-type-style .input-style input:focus,
.input-type-style .input-error input:focus {
  box-shadow: none;
}
.input-type-style .input-style .quill .ql-toolbar,
.input-type-style .input-error .quill .ql-toolbar {
  border-top-color: #ff5046;
  border-right-color: #ff5046;
  border-left-color: #ff5046;
}
.input-type-style .input-style .quill .ql-container,
.input-type-style .input-error .quill .ql-container {
  border-left-color: #ff5046;
  border-right-color: #ff5046;
  border-bottom-color: #ff5046;
}
.input-type-style.input-type-border .input-style {
  border: 2px solid #dddddd;
}
.input-type-style.input-type-border .input-style textarea {
  padding: 1rem;
}
.input-type-style.input-type-border .input-style:before {
  display: none;
}
.input-type-style.input-type-border .input-style:hover {
  border: 2px solid #3f928b;
}
.input-type-style.input-type-border .input-style:hover:before {
  display: none;
}
.input-type-style.input-type-border .input-style:focus-within {
  border: 2px solid #039f97;
}
.input-type-style.input-type-border .input-style:focus-within:after {
  display: none;
}
.input-type-style.input-type-border .input-label-style {
  padding-bottom: 1rem;
}
.input-type-style.input-type-border .input-error {
  border: 2px solid #ff5046;
}
.input-type-style .quill .ql-toolbar {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.input-type-style .quill .ql-container {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.input-type-style .input-style,
.input-type-style .input-error,
.input-type-style .dropdown__control {
  border: none;
  position: relative;
}
.input-type-style .input-style input,
.input-type-style .input-error input,
.input-type-style .dropdown__control input {
  border: none;
  width: 100%;
  background: transparent;
  font-family: "Open Sans", sans-serif;
}
.input-type-style .input-style:hover:before,
.input-type-style .input-error:hover:before,
.input-type-style .dropdown__control:hover:before {
  border-bottom: 2px solid #3f928b;
}
.input-type-style .input-style:focus-within:after,
.input-type-style .input-error:focus-within:after,
.input-type-style .dropdown__control:focus-within:after {
  transform: scaleX(1);
}
.input-type-style .input-style:before,
.input-type-style .input-error:before,
.input-type-style .dropdown__control:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}
.input-type-style .input-style:after,
.input-type-style .input-error:after,
.input-type-style .dropdown__control:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #039f97;
  pointer-events: none;
}
.input-type-style .input-label-style {
  padding-bottom: 0.4rem;
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(17, 17, 17, 0.6);
  transform: translate(-10%, -55%) scale(0.9);
  font-weight: 600;
  margin-left: 4px;
}
.input-type-style .input-label-style.text-area-label {
  font-size: 14px;
  position: relative;
  margin-left: 0;
}
.input-type-style .dropdown__placeholder {
  margin: 0;
  margin-left: 0.1rem;
}
.input-type-style .dropdown__value-container {
  padding: 0;
}
.input-type-style .dropdown__indicator-separator {
  display: none;
}
.input-type-style .dropdown--is-disabled .dropdown__control {
  background: transparent;
}
.input-type-style .dropdown--is-disabled .dropdown__control:before {
  border-bottom-style: dotted;
}
.input-type-style .dropdown--is-disabled + .input-label-style {
  opacity: 0.5;
}
.input-type-style .dropdown--is-disabled .dropdown__placeholder {
  opacity: 0.5;
}
.input-type-style .dropdown__control--is-focused {
  border: none;
  box-shadow: none;
}
.input-type-style .dropdown__control--is-focused .dropdown__indicator-separator {
  width: 0;
}
.input-type-style .dropdown__control--is-focused .dropdown__value-container {
  padding-left: 0;
}
.input-type-style .dropdown__value-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: unset;
  margin-bottom: 7px;
}
.input-type-style .dropdown__value-container .dropdown__single-value {
  transform: none;
  transform: initial;
  margin: 0;
  position: relative;
  overflow: visible;
  overflow: initial;
  max-width: none;
  max-width: initial;
  white-space: normal;
  white-space: initial;
  top: 0;
  box-sizing: inherit;
  text-overflow: initial;
  min-height: 0;
  min-height: initial;
}
.input-type-style:focus-within .input-label-style {
  color: #039f97;
}
.input-type-style .input-error:before {
  border-color: #ff5046;
}
.input-type-style .input-error .dropdown__control:before {
  border-color: #ff5046;
}
.input-type-style .input-error .dropdown__control .css-1g6gooi {
  margin: 0;
  padding: 0;
}
.input-type-style .input-error .dropdown__menu {
  color: #313131;
}
.input-type-style .input-error + .input-label-style {
  color: #ff5046;
}
.input-type-style .input-error + .input-label-style + .input-helper {
  color: #ff5046;
}
.input-type-style .input-error + .input-helper {
  color: #ff5046;
}
.input-type-style .input-helper {
  font-size: 12px;
  color: #585858;
}

.field-set {
  display: flex;
  margin-top: 2.5rem;
}
.field-set h3 {
  font-size: 1.25rem;
  font-weight: 600;
}
.field-set .field-set-line {
  padding: 0;
  margin-left: 2rem;
  flex: 1 1 auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  border-bottom: 1px solid #dddddd;
}

.field-set-slot {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
}

.sub-field-set {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 1rem;
}

.page-title {
  display: flex;
  height: 100%;
}
.page-title h3 {
  font-size: 1.25em;
  color: #a7985f;
  align-self: center;
}
.page-title-line {
  padding: 0;
  margin-left: 2rem;
  flex: 1 1;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  border-bottom: 1px solid #dddddd;
}

.list-base {
  max-width: 64rem;
  padding: 0 1rem;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  margin: auto;
}
.list-base h2 {
  padding-top: 1em;
  padding-bottom: 0.5em;
}
.list-base .ReactTable {
  box-shadow: 2px 10px 16px 1px rgba(17, 17, 17, 0.1);
  table-layout: fixed;
  background-color: #fff;
  display: table;
  width: 100%;
  margin: 1rem;
  max-width: 100%;
  border: none;
}
.list-base .ReactTable .rt-table .rt-thead {
  background: #fff;
  box-shadow: none;
}
.list-base .ReactTable .rt-table .rt-thead .rt-th {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  padding: 1rem;
  display: table-cell;
  cursor: pointer;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  border: none;
  text-align: left;
}
.list-base .ReactTable .rt-table .rt-thead .rt-th:nth-child(7) input {
  display: none;
}
.list-base .ReactTable .rt-table .rt-tbody {
  border-top: 1px solid #dddddd;
}
.list-base .ReactTable .rt-table .rt-tbody .rt-tr-group {
  border: none;
}
.list-base .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr {
  background: linear-gradient(rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)) center bottom/100% 1px no-repeat;
  align-items: center;
}
.list-base .ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
  border: none;
  padding: 1rem;
}
.list-base .ReactTable .select-wrap {
  display: none;
}

.list-base-width {
  max-width: none !important;
}
.list-base-width .ReactTable {
  margin: 0 !important;
}

.table-filters {
  color: #208fdc;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next,
.ReactTable .-pagination .-center {
    font-size: 12px;
  }
  .ReactTable .-pagination .-btn {
    width: 100% !important;
    border-radius: 0px;
  }
}
.card {
  box-shadow: 0 4px 6px 0 rgba(17, 17, 17, 0.1);
  border-radius: 3px;
  background: #fff;
}
.card .card-header {
  padding: 1rem;
  background: linear-gradient(90deg, #00a397 0, #039f97);
  border-radius: 3px 3px 0 0;
  position: relative;
  color: #c6efeb;
}
.card .card-header h3 {
  font-size: 16px;
  margin: 0 !important;
  font-weight: 400;
}
.card-list {
  padding: 10px;
  transition: all 0.2s;
}
.card-list:nth-child(odd) {
  background: white;
}
.card-list:hover {
  transition: all 0.2s;
  background: #eaeaea;
}
.card-list a {
  color: unset;
}
.card-list a:hover {
  text-decoration: none;
  color: unset;
}

.p-card {
  position: relative;
}

.spinner-base {
  position: relative;
}
.spinner-base .spinner {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: 1px solid;
  position: absolute;
  border-top-color: #f8f8f8;
}
.spinner-base .blur {
  -webkit-filter: blur(4px);
          filter: blur(4px);
  transition: all 0.25s ease;
}

.spin {
  animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  0% {
    -webkit-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  0% {
    -webkit-transform: rotate(-360deg);
  }
}
.float-button {
  width: 50px;
  height: 50px;
  background: #039f97;
  font-size: 18px;
  position: fixed;
  right: 3rem;
  border-radius: 50%;
  bottom: 2rem;
  border: none;
  display: flex;
  justify-content: center;
  color: white;
  box-shadow: 2px 10px 16px 1px rgba(17, 17, 17, 0.1);
}

.financer-float {
  position: fixed;
  right: 4rem;
  bottom: 2rem;
  background: white;
  width: 450px;
  height: 600px;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 15px;
  box-shadow: 2px 10px 16px 1px rgba(17, 17, 17, 0.1);
}

.text-area-float {
  width: 100%;
}

/* custom check */
.container-check-custom {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
}
.container-check-custom input[type=checkbox],
.container-check-custom input[type=radio] {
  position: absolute;
  top: 5px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
.container-check-custom .container-check-custom-label {
  margin-top: 2px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  left: 0px;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  transition: all 0.3s ease;
  border: 1px solid #dddddd;
}

/* On mouse-over, add a grey background color */
.container-check-custom:hover > input ~ .checkmark, .container-check-custom:hover > input ~ .radio-check {
  background-color: #ccc;
  transition: all 0.3s ease;
  cursor: pointer;
}
.container-check-custom input:checked ~ .checkmark {
  background-color: #039f97;
}
.container-check-custom input:checked ~ .radio-check:after {
  background: #585858;
}

.radio-check {
  position: absolute;
  top: 5px;
  left: 0px;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: all 0.3s ease;
  border: 1px solid #c7c7c7;
}

.radio-check:after {
  content: "";
  left: 2px;
  top: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border-width: 0 3px 3px 0;
}

.container-check-custom-min-width {
  min-width: 70px !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-check-custom input:checked ~ .checkmark:after {
  display: block;
}
.container-check-custom .checkmark:after {
  left: 5px;
  top: 2px;
  width: 8px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.container-check-custom input:checked ~ .radio-check:after {
  display: block;
}
.container-check-custom .radio-check:after {
  position: absolute;
}
.container-check-custom.disabled {
  opacity: 0.3;
}

/* Style the checkmark/indicator */
.navigation {
  top: 0;
  left: 0;
  z-index: 4;
  color: #fff;
  width: 100%;
  height: 7rem;
  background: #039f97;
  padding: 0;
}
.navigation .navigation-adjustment {
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  justify-content: space-between;
  -webkit-box-pack: justify;
  margin: auto;
  padding: 15px 25px;
  max-width: 74rem;
}
.navigation .navigation-adjustment .navigation-logo {
  height: 5rem;
  max-width: 100%;
}
.navigation .navigation-adjustment .navigation-info-user {
  display: grid;
  text-align: right;
}
.navigation .navigation-adjustment .navigation-info-user .name {
  font-weight: 700;
  font-size: 0.7rem;
}
.navigation .navigation-adjustment .navigation-info-user .cpf {
  font-size: 0.7rem;
  margin-top: 5px;
}
.navigation .navigation-adjustment .navigation-info-user .cpf .destaque {
  font-weight: 700;
  color: #fdf1d0;
}

.navigation-notification {
  background: #039f97;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 2px 10px 16px 1px rgba(17, 17, 17, 0.1);
}
.navigation-notification .notification-notify {
  z-index: 2;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  background: #ff5046;
  color: #fff;
}

.navigation-notification-button {
  width: 100%;
  height: 100%;
}

.navigation-student {
  top: 0;
  left: 0;
  z-index: 10;
  color: #fff;
  width: 100%;
  height: 6rem;
  background: #039f97;
  padding: 0;
  position: fixed;
}
.navigation-student ul {
  list-style: none;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  justify-content: space-between;
  -webkit-box-pack: justify;
  margin: auto;
  padding: 15px 25px;
}
.navigation-student ul .logo {
  align-self: center;
}
.navigation-student ul li {
  margin-right: 20px;
}
.navigation-student ul li:last-child {
  margin-right: 0;
}
.navigation-student .user-info {
  display: grid;
  text-align: right;
  flex: 1 1;
}
.navigation-student .user-info .name {
  font-weight: 700;
  font-size: 0.8rem;
}
.navigation-student .user-info .cpf {
  font-size: 0.7rem;
}
.navigation-student .user-info .cpf .destaque {
  font-weight: 700;
  color: #fdf1d0;
}
.navigation-student .navigation-notification {
  background: #077772;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: none;
}
.navigation-student .navigation-notification i {
  color: #fdf1d0;
  font-size: 16px;
}
.navigation-student .navigation-notification .notification-notify {
  z-index: 2;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  background: #ff5046;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .navigation-notification {
    display: none !important;
  }

  .navigation-student {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .navigation-student {
    display: none;
  }
}
@media print {
  .navigation-student {
    display: none;
  }
}
.notification-side-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  color: #111;
  background-color: #fff;
  box-shadow: 2px 10px 16px 1px rgba(17, 17, 17, 0.1);
  width: 16rem;
  padding-top: 1rem;
  z-index: 9;
}
.notification-side-menu .notification-base {
  display: flex;
  display: -webkit-box;
  padding: 1rem;
  align-items: center;
  -webkit-box-align: center;
  border-bottom: 1px solid #dddddd;
}

.content-area {
  padding-left: calc(260px + 2rem);
  transition: padding-left 0.3s;
  padding-top: calc(122px + 2rem);
}

@media only screen and (max-width: 1200px) {
  .content-area {
    padding-left: calc(80px + 2rem);
    transition: padding-left 0.3s;
  }
}
@media only screen and (max-width: 768px) {
  .content-area {
    padding-left: 2rem !important;
    padding-top: calc(3.5rem + 122px);
    transition: padding-top 0.3s;
  }

  .sidebar-base {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .content-area {
    padding-left: 0 !important;
    padding-right: 0;
  }
}
@media print {
  .sidebar-base {
    display: none;
  }
}
.sidebar-base {
  background: white;
  width: 300px;
  position: fixed;
  left: 0;
  top: 96px;
  bottom: 0;
  box-shadow: 0px 9px 25px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: width 0.5s;
  overflow: auto;
}
.sidebar-base.collapsed {
  width: 80px;
  overflow-x: hidden;
}
.sidebar-base.collapsed .sidebar-action {
  transition: width 0.5s;
  width: 80px;
}
.sidebar-base.collapsed .sidebar-action span {
  display: none;
}
.sidebar-base.collapsed .menu-element span {
  display: none;
}
.sidebar-base.collapsed ~ .content-area {
  padding-left: calc(80px + 2rem);
  transition: padding-left 0.3s;
}
.sidebar-base .sidebar-action {
  background-color: #fff;
  display: flex;
  padding: 20px;
  width: 300px;
  position: fixed;
  z-index: 99;
  bottom: 0;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-out;
  transition: width 0.5s;
}
.sidebar-base .sidebar-action:hover {
  transition: width 0.5s;
  background: #f5f5f5;
  opacity: 1;
}
.sidebar-base .sidebar-text {
  margin-left: 1rem;
  color: #5f5f5f;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
}
.sidebar-base .icon-sidebar {
  color: #5f5f5f;
  font-size: 16px;
  margin-left: 6px;
}
.sidebar-base .menu-list {
  padding: 20px;
  padding-bottom: 3rem;
  padding-top: 0;
  list-style-type: none;
  font-weight: 400;
}
.sidebar-base .menu-list .menu-element {
  padding: 15px 0;
  white-space: nowrap;
}
.sidebar-base .menu-list .menu-element button {
  background: transparent;
  border: none;
  font-size: 14px;
  outline: none;
}
.sidebar-base .menu-list .menu-element:last-child {
  border-bottom: none;
}
.sidebar-base .menu-list .menu-element .active-menu {
  font-weight: 400;
}
.sidebar-base .menu-list .menu-element .active-menu .menu-icon {
  color: white;
  background: #039f97;
  transition: 0.2s;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.sidebar-base .menu-list .menu-element .active-menu .menu-icon-border {
  background: #039f97;
}
.sidebar-base .menu-list .menu-element .menu-icon-border {
  border: 1px solid #039f97;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.sidebar-base .menu-list .menu-element .menu-icon {
  color: #039f97;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
  transition: 0.2s;
}
.sidebar-base .menu-list .menu-element p {
  font-size: 14px;
  font-weight: 500;
  color: #5f5f5f;
  margin: 0;
}
.sidebar-base .menu-list-internal {
  padding-top: 15px;
  list-style-type: none;
  padding-left: 43px;
}
.sidebar-base .menu-list-internal li {
  font-size: 14px;
  padding-bottom: 10px;
  white-space: normal;
}
.sidebar-base .menu-list-internal li .active-link {
  color: #a7985f;
  font-weight: bold;
}
.sidebar-base .menu-list-internal li a {
  color: #5f5f5f;
}
.sidebar-base .menu-list-internal li a:hover {
  color: #a7985f;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}
.tooltip-container .tooltip-text {
  background: #039f97;
  color: white;
  font-size: 10px;
  word-wrap: break-word;
  border-radius: 3px;
  bottom: 130%;
  display: block;
  left: 0px;
  opacity: 0;
  padding: 15px;
  pointer-events: none;
  position: absolute;
  max-width: 13rem;
  min-width: 10rem;
  z-index: 1102;
  transform: translateY(5px);
  transition: all 0.2s ease-out;
}
.tooltip-container .tooltip-text:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #039f97 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 20%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}
.tooltip-container:hover .tooltip-text {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0px);
}

.step-content {
  padding: 1rem;
  border-radius: 3px;
  background-color: #f8f8f8;
  box-shadow: rgba(17, 17, 17, 0.1);
  z-index: -1;
}
.step-content .step-content-slot {
  background-color: #fff;
  padding: 1rem;
}

.stepper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
.stepper .line {
  position: absolute;
  top: 9px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: calc(100% - 5.5rem);
  height: 1px;
  background: rgba(17, 17, 17, 0.1);
  border: 1px solid rgba(17, 17, 17, 0.1);
}
.stepper .step {
  text-align: center;
  width: 100px;
  z-index: 1;
}
.stepper .step:hover {
  cursor: pointer;
}
.stepper .step p {
  font-size: 12px;
  color: #313131;
}
.stepper .step--icon {
  height: 18px;
  width: 18px;
  margin: auto;
  background: #f5f5f5;
  border: 1px solid #d0d0d0;
  z-index: 1;
  border-radius: 50%;
}

.enrollment-flex {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.enrollment-flex .col-md-4,
.enrollment-flex .col-sm-12 {
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}
.enrollment-flex .col-md-4 h6,
.enrollment-flex .col-sm-12 h6 {
  font-weight: normal;
  font-size: 0.75rem;
}
.enrollment-flex .col-md-4 h4,
.enrollment-flex .col-sm-12 h4 {
  font-weight: normal;
  font-size: 0.9rem;
}

.summary-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.documents-base {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}
.documents-base .ball-style {
  margin: 0 !important;
}
.documents-base .documents-uploaded {
  background: #ffffc8;
  color: #111;
  padding: 1rem;
}
.documents-base .documents-uploaded .ball-style {
  margin: 0 !important;
  margin-right: 0.5rem !important;
}
.documents-base .documents-uploaded .documents-length-0 {
  flex: 1 1 auto;
  word-wrap: break-word;
  width: 80%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  font-size: 14px;
}
.documents-base .documents-upload {
  display: flex;
  display: flex;
  padding: 1rem;
  align-items: center;
}
.documents-base .documents-upload-description {
  flex: 1 1 auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  margin-left: 1.5rem;
}
.documents-base .documents-upload-description p {
  margin-bottom: 0;
}
.documents-base .documents-upload-description p:nth-child(1) {
  text-decoration: underline;
}
.documents-base .documents-upload-description p:nth-child(2) {
  color: rgba(17, 17, 17, 0.6);
}

.documents-none {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 0;
}

.modal-header {
  font-size: 18px;
  font-weight: bold;
}

iframe {
  width: 100%;
  height: 520px;
  border: 5px solid #eee;
  border-radius: 3px;
}

.modal-body-pendencie {
  padding: 15px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow-y: scroll;
  height: 90%;
}

.pendencies {
  overflow-y: auto;
  border-bottom: 1px solid #dddddd;
  padding: 15px;
}
.pendencies-header-title {
  font-size: 18px;
  letter-spacing: 1px;
  color: #039f97;
}
.pendencies-body {
  color: #111;
}
.pendencies-date {
  margin-top: 15px;
  font-size: 12px;
  color: #5f5f5f;
  margin-bottom: 0.25rem;
  float: right;
}

.modal-basic {
  min-width: 760px;
}

@media all and (max-width: 768px) {
  .modal-basic {
    min-width: 350px;
  }

  .modal-base {
    width: 98% !important;
  }
  .modal-base .p-dialog-content {
    width: 100% !important;
    padding: 2px;
  }

  .modal-content .p-dialog-content {
    height: 70% !important;
  }
  .modal-content .p-dialog-content .p-datepicker {
    padding-right: 1rem;
    padding-bottom: 2rem;
  }
}
.modal-base {
  width: 55rem;
}
.modal-base .p-dialog-titlebar {
  background-color: white !important;
  border: none !important;
  padding: 2rem 2rem 0 2rem !important;
  font-size: 16px;
  color: #a7985f !important;
}
.modal-base .p-dialog-content {
  border: none !important;
  padding: 1rem 2rem 2rem 2rem !important;
}
.modal-base.fit-content {
  height: unset;
  width: unset;
}

.modal-terms {
  height: 100%;
  width: 100%;
}
.modal-terms .p-dialog-content {
  overflow-y: scroll;
  height: 100%;
}

.modal-content {
  height: 500px;
}
.modal-content .p-dialog-content {
  overflow-y: scroll;
  margin-right: 0;
  width: 55rem;
  height: 100%;
}

.modal-events {
  height: 300px;
  width: 30rem;
}
.modal-events .p-dialog-titlebar {
  padding: 1.5rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-events .p-dialog-content {
  width: 30rem;
  overflow-y: scroll;
  margin-right: 0;
  height: 100%;
}

@media (max-width: 480px) {
  .modal-events {
    width: 100%;
  }
  .modal-events .p-dialog-content {
    width: 100%;
  }
}
.footer-base {
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  justify-content: space-between;
  -webkit-box-pack: justify;
  background: linear-gradient(90deg, #00a397 0, #039f97);
  color: #c6efeb;
  padding: 2.5rem;
  width: 100%;
}
.footer-base p {
  margin-bottom: 0;
}
.footer-base .footer-side {
  display: flex;
  align-items: center;
}
.footer-base .footer-side i {
  color: #c6efeb;
}
.footer-base .footer-side a {
  color: #c6efeb;
  text-decoration: none;
  padding-left: 10px;
  margin-bottom: 0;
}
.footer-base .footer-side .footer-logo {
  min-width: 7rem;
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #fff;
}

.modal-summary .rodal-dialog {
  overflow-x: auto;
  padding: 3rem;
}

.tabs-nav {
  display: inline-flex;
  text-align: right;
  list-style: none;
  justify-content: flex-end;
  flex: 1 1;
}
.tabs-nav li {
  padding: 10px 5px;
}
.tabs-nav .tab--active p {
  border-bottom: 2px solid #039f97;
}
.tabs-nav .tab--normal p {
  border-bottom: 2px solid transparent !important;
  color: rgba(17, 17, 17, 0.6);
}

@media screen and (max-width: 768px) {
  .tabs-nav {
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media (max-width: 480px) {
  .tabs-nav {
    font-size: 16px !important;
    display: flex;
    justify-content: flex-end !important;
  }
  .tabs-nav li {
    width: auto !important;
  }
  .tabs-nav li:first-child() {
    margin-right: 2rem;
  }
}
.table-default {
  border: none;
}
@media (max-width: 769px) {
  .table-default .-pagination .-pageSizeOptions {
    position: static !important;
    position: initial !important;
  }
}
@media (max-width: 490px) {
  .table-default .-center {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
.table-default .-header {
  box-shadow: 5px 7px 8px 1px rgba(0, 0, 0, 0.15);
}
.table-default .rt-thead .rt-th {
  color: #585858;
  font-weight: 600;
  text-align: left;
  padding: 10px 10px !important;
  border-color: #dddddd;
  border-bottom: 1px solid #dddddd;
  overflow: visible !important;
  white-space: normal !important;
  display: flex;
  align-items: center;
}
.table-default .rt-thead .-sort-desc:after {
  content: "▼";
  position: absolute;
  top: 8px;
  right: 10px;
}
.table-default .rt-thead .-sort-asc:before {
  content: "▲";
  position: absolute;
  top: 8px;
  right: 10px;
}
.table-default .rt-thead .rt-th.-sort-desc {
  box-shadow: none;
  font-weight: 600;
}
.table-default .rt-tbody {
  border: none;
  overflow: visible !important;
  overflow: initial !important;
}
.table-default .rt-tbody .rt-tr {
  align-items: center;
}
.table-default .rt-tbody .-odd {
  background: white !important;
}
.table-default .rt-tbody .-even {
  background: #f8f8f8;
}
.table-default .rt-tbody .rt-td {
  color: #585858;
  padding: 10px 10px !important;
  border-color: #dddddd;
  overflow: visible;
  white-space: normal;
  min-height: 60px;
  display: flex;
  align-items: center;
}
.table-default .rt-tbody .rt-td p {
  margin: 0;
}
.table-default .rt-tbody .rt-td .column-label {
  display: none;
}
.table-default .rt-tbody .rt-td .table-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-default .rt-tbody .rt-td .container-check-custom {
  display: inline;
}

@media all and (max-width: 768px) {
  .table-responsive {
    padding: none;
  }
  .table-responsive .rt-thead .rt-tr {
    display: none;
  }
  .table-responsive .rt-tbody {
    font-size: 13px;
    min-width: inherit !important;
  }
  .table-responsive .rt-tbody .rt-tr {
    display: block;
  }
  .table-responsive .rt-tbody .rt-td {
    max-width: 100% !important;
    width: 100% !important;
    border: none;
    border-bottom: 1px solid #eaeaea;
    position: relative;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    min-height: 29px;
  }
  .table-responsive .rt-tbody .rt-td .responsive-row {
    text-align: left;
    padding-left: 55% !important;
  }
  .table-responsive .rt-tbody .rt-td .table-buttons {
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  .table-responsive .rt-tbody .rt-td .column-label {
    width: 50%;
    display: block;
    position: absolute;
    left: 1rem;
    font-weight: bold;
  }
}
.expanded-rows .rt-tbody .rt-td {
  padding: 1.5rem 1rem !important;
}

.tabela-horario .rt-tbody .rt-tr {
  font-size: 12px;
  color: #5f5f5f;
}

.-btn {
  width: 60% !important;
}

.-next .-btn {
  float: right;
}

.break-line {
  overflow: visible;
  white-space: normal !important;
}

.highlight-info {
  background: #efebd8;
  color: #585858;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 3rem;
  align-items: center;
  border-radius: 6px;
}
.highlight-info p {
  margin: 0;
}
.highlight-info--button {
  background: #e2d7a6;
  border-radius: 6px;
  padding: 5px 10px;
  max-width: 235px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 29px;
  outline: none;
}

.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.ausente.slider {
  background: #b77474;
}

.slider:before {
  position: absolute;
  content: "A";
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #13b2b9;
}
input:checked + .slider:before {
  content: "P" !important;
  text-align: center;
  font-size: 13px;
  transition: 0.4s;
  font-weight: 600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #13b2b9 !important;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.p-calendar .p-datepicker {
  background-color: #fafafa;
  border: none !important;
  padding: 0;
}
.p-calendar .p-datepicker .p-datepicker-header {
  background-color: #fafafa;
  padding: 1em 2em;
}
.p-calendar .p-datepicker .p-datepicker-header .p-datepicker-prev {
  left: 2.125em;
}
.p-calendar .p-datepicker .p-datepicker-header .p-datepicker-next {
  right: 2.125em;
}
.p-calendar .p-datepicker .p-datepicker-header .p-link {
  top: 9px;
}
.p-calendar .p-datepicker table td > span.p-highlight > div {
  color: #fff !important;
}
.p-calendar .p-datepicker table td.p-datepicker-today > span.p-highlight {
  background-color: #039f97;
  border-radius: 25px;
}
.p-calendar .p-datepicker table td > span.p-highlight {
  background-color: #039f97;
  border-radius: 25px;
}
.p-calendar .p-datepicker table td.p-datepicker-today > span {
  border-radius: 25px;
}
.p-calendar .p-datepicker table td.p-datepicker-today > span {
  border-radius: 25px;
}
.p-calendar .p-datepicker table td > span {
  border-radius: 25px;
}
.p-calendar .p-datepicker .p-datepicker-header .p-datepicker-title {
  font-weight: 700;
}

.lessons-calendar .p-highlight {
  background-color: #efebd8 !important;
  border-radius: 5%;
}

.mes-atual {
  background: #039f97;
  color: #fff;
  border-radius: 4px 4px 0 0;
  font-size: 18px;
  letter-spacing: 1px;
}
.mes-atual span {
  font-size: 11px;
  font-weight: 400;
}

.year-calendar {
  max-width: 290px;
}
.year-calendar .p-datepicker {
  background-color: #fafafa;
  border: none !important;
  padding: 0;
}
.year-calendar .p-datepicker .p-datepicker-header {
  background-color: #fafafa;
  padding: 1em 2em;
}
.year-calendar .p-datepicker .p-datepicker-header .p-datepicker-prev {
  left: 2.125em;
}
.year-calendar .p-datepicker .p-datepicker-header .p-datepicker-next {
  right: 2.125em;
}
.year-calendar .p-datepicker .p-datepicker-header .p-link {
  top: 9px;
}
.year-calendar .p-datepicker .p-monthpicker {
  padding: 2em 0;
  text-transform: uppercase;
  font-size: 13px;
}
.year-calendar .p-datepicker .p-monthpicker .p-highlight {
  background-color: #e2d7a6 !important;
}
.year-calendar .p-datepicker .p-monthpicker .p-monthpicker-month {
  color: #f8f8f8;
}
.year-calendar .p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 10px 5px;
}

.calendar-inline-form {
  width: 100%;
}
.calendar-inline-form input {
  min-width: 200px;
  outline: none;
  font-size: 0.875rem;
  border: 0;
  padding-top: 19px;
  padding-bottom: 11.5px;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  background: linear-gradient(#039f97, #039f97) center bottom/0 2px no-repeat, linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) center bottom/100% 1px no-repeat;
  background-color: none;
  position: relative;
  width: 100%;
  border-radius: 0px;
}
.calendar-inline-form input:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none !important;
  border-color: unset !important;
  background-size: 100% 2px, 100% 1px;
}

.calendar-error input {
  background: linear-gradient(#039f97, #039f97) center bottom/0 2px no-repeat, linear-gradient(#e6646e, #e6646e) center bottom/100% 1px no-repeat;
}
.calendar-error input::-webkit-input-placeholder {
  color: #e6646e;
}
.calendar-error input::-ms-input-placeholder {
  color: #e6646e;
}
.calendar-error input::placeholder {
  color: #e6646e;
}

.inline-calendar {
  min-width: 200px;
}
.inline-calendar label {
  padding-bottom: 3rem !important;
}

.calendar-attendance {
  width: 100%;
  display: flex;
  justify-content: center;
}
.calendar-attendance .inline-calendar {
  max-width: 540px;
}

.p-datepicker.p-component.p-input-overlay.p-shadow.p-input-overlay-visible {
  top: 50px !important;
}

.p-datepicker-calendar {
  margin-top: 0 !important;
}

.calendar-base-layout {
  margin-right: 1rem;
}
.calendar-base-layout td span {
  border-radius: 50% !important;
  background: #039f97;
}
.calendar-base-layout td span.p-disabled {
  background: transparent;
}
.calendar-base-layout td span.p-disabled div {
  color: #313131 !important;
}
.calendar-base-layout td span.p-highlight {
  background-color: #077772 !important;
}
.calendar-base-layout td span.p-highlight div {
  color: white !important;
}
.calendar-base-layout td span div {
  color: white !important;
  font-weight: 600;
  font-size: 15px;
}
.calendar-base-layout td span:hover {
  background-color: #077772 !important;
}
.calendar-base-layout td span:hover div {
  color: white !important;
}

.loading-calendar {
  position: relative;
}
.loading-calendar .p-datepicker-calendar-container {
  opacity: 0;
}
.loading-calendar:after {
  font-family: "Font Awesome 5 Free";
  content: "";
  color: #039f97;
  font-weight: 900;
  position: absolute;
  -webkit-animation: pulse 2s infinite linear;
  animation: pulse 2s infinite linear;
  top: 50%;
  right: 50%;
}

.p-datepicker-header {
  background-color: #039f97 !important;
  color: white !important;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
}
.p-datepicker-header button {
  top: 0 !important;
  bottom: 0;
  margin: auto;
  color: white !important;
}

.p-datepicker-calendar-container {
  background: white;
}
.p-datepicker-calendar-container thead {
  color: #313131;
}

@media (max-width: 480px) {
  .p-datepicker-header {
    font-size: 16px !important;
  }

  .p-datepicker {
    left: -10px !important;
  }

  .p-datepicker table td {
    padding: 10px !important;
  }
}
@media (max-width: 420px) {
  .p-datepicker table td {
    padding: 7px !important;
  }
}
@media (max-width: 380px) {
  .p-datepicker table td {
    padding: 4px !important;
  }
}
@media (max-width: 365px) {
  .p-datepicker table td {
    padding: 3px !important;
  }
}
@media (max-width: 330px) {
  .p-datepicker table td {
    padding: 0px !important;
  }
}
.number-box {
  background: #f2f2f2;
  text-align: center;
  padding: 1em;
  border-radius: 5px;
  margin-right: 2em;
  width: 130px;
  height: 130px;
}
.number-box-circle {
  border-radius: 50%;
  background: white;
  font-size: 17px;
  font-weight: 600;
  height: 45px;
  width: 45px;
  margin: auto;
  display: block;
  line-height: 2.6em;
  box-shadow: 5px 7px 8px 1px rgba(0, 0, 0, 0.15);
  color: #039f97;
  margin-bottom: 15px;
}
.number-box-text {
  margin-top: 20px;
  font-weight: 300;
  font-size: 12px;
  color: #5f5f5f;
}

.panel {
  background: #f5f5f5;
  border: none;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0 4px 6px 0 rgba(17, 17, 17, 0.1);
}
.panel .panel-header {
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 3px 3px 0 0;
  position: relative;
  border: none;
  color: #a7985f;
  font-size: 13px;
  font-weight: 500;
}
.panel .panel-header h3 {
  font-size: 16px;
  margin: 0;
  font-weight: 400;
}
.panel-list {
  padding: 10px;
  transition: all 0.2s;
  position: relative;
}
.panel-list:nth-child(odd) {
  background: #fff;
}
.panel-list:hover {
  transition: all 0.2s;
  background: #eaeaea;
}
.panel-list a {
  color: unset;
}
.panel-list a:hover {
  text-decoration: none;
  color: unset;
}
.panel .panel-body {
  color: #585858;
  padding-top: 5px;
  position: relative;
}
.panel .panel-body .content-panel {
  display: flex;
  justify-content: space-between;
}
.panel .panel-body .content-panel .content-title {
  color: #aaaaaa;
  font-weight: 600;
}

.right-arrow {
  align-self: center;
  padding: 20;
}

.btn-primary, .btn-danger {
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.btn {
  -webkit-appearance: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 13px;
  height: auto;
  line-height: 1.125rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  border-radius: 100px;
}
.btn:last-child {
  margin-right: 0;
}
.btn-w50 {
  max-width: 50%;
}
.btn:focus {
  outline: none;
}
.btn-small {
  font-size: 12px;
  padding: 5px 10px;
  font-weight: 600;
}
.btn-medium {
  font-size: 14px;
}
.btn-large {
  font-size: 16px;
}
.btn-primary {
  background-color: #039f97;
  border: 2px solid #039f97;
  font-weight: 600;
}
.btn-primary:hover {
  background-color: #3f928b;
  border-color: transparent;
  text-decoration: none;
  color: #fff;
}
.btn-primary:active {
  background-color: #3f928b;
  border-color: transparent;
  text-decoration: none;
}
.btn-primary:disabled {
  background-color: rgba(17, 17, 17, 0.1);
  opacity: 0.5;
  font-weight: 600;
  border-color: #aaaaaa;
}
.btn-primary:disabled:hover {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  box-shadow: #585858;
  cursor: not-allowed;
}
.btn-danger {
  background-color: #e6646e;
  border: 2px solid #e6646e;
}
.btn-danger:hover {
  background-color: #c84f59;
  border-color: transparent;
  text-decoration: none;
}
.btn-collapse {
  padding: 0;
  margin: 0;
  height: 20px;
  margin: 0 0.5rem;
  border: none;
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  color: #707070;
}
.btn-add {
  font-size: 12px;
  background: none;
  border: none;
}
.btn-add span {
  color: #585858;
}
.btn-add svg {
  align-self: center;
  font-size: 17px;
  margin-right: 0.5rem;
}
.btn-add svg:hover {
  color: #3f928b;
}
.btn-add:hover {
  color: #111;
}
.btn-default {
  background: #fff;
  border: 2px solid #039f97;
  color: #039f97;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.btn-default:hover {
  border-color: #3f928b;
  color: #3f928b;
}
.btn-default:active {
  border-color: #3f928b;
  color: #3f928b;
}
.btn-default:disabled {
  border-color: #aaaaaa;
  color: #aaaaaa;
  background-color: rgba(17, 17, 17, 0.1);
}
.btn-default:disabled:hover {
  border-color: #aaaaaa;
  box-shadow: none;
  cursor: not-allowed;
}
.btn-link-on-text {
  font-size: 14px;
  padding: 0;
  margin: 0;
  margin-left: 8px;
  background: transparent;
  border: none;
  color: #039f97;
  text-decoration: underline;
}
.btn-link-on-text:hover {
  color: #7d7140;
}
.btn-link-on-text:active {
  color: #7d7140;
}
.btn-link-on-text:disabled {
  border-color: #aaaaaa;
  color: #aaaaaa;
  background-color: rgba(17, 17, 17, 0.1);
}
.btn-link-on-text:disabled:hover {
  border-color: #aaaaaa;
  box-shadow: none;
  cursor: not-allowed;
}
.btn-link {
  background: transparent;
  border: none;
  color: #039f97;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: underline;
}
.btn-link:hover {
  color: #7d7140;
  background: transparent;
  border: none;
  box-shadow: none;
  text-decoration: underline;
}
.btn-link:active {
  color: #7d7140;
}
.btn-link:disabled {
  color: #aaaaaa;
  border-color: transparent;
  background-color: transparent;
}
.btn-link:disabled:hover {
  border-color: #aaaaaa;
  box-shadow: none;
  background: transparent;
  cursor: not-allowed;
}
.btn-link.white {
  color: #fff;
}
.btn-link.white:hover {
  color: #aaaaaa;
}
.btn-help {
  background: transparent;
  border-color: transparent;
  color: #aaaaaa;
  font-weight: 600;
  text-decoration: underline;
  padding: 0;
  font-size: 10px;
}
.btn-help:hover {
  color: #5f5f5f;
}
.btn-help:active {
  color: #5f5f5f;
}
.btn-secondary {
  background-color: white;
  border: none;
  color: #5f5f5f;
  text-transform: none;
  font-weight: 600;
  border-radius: 6px;
  min-width: 100px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 16px;
}
.btn-secondary.active, .btn-secondary:active {
  background-color: #eaeaea;
}
.btn-black {
  background-color: #5f5f5f;
  border-radius: 3px;
  color: #fff;
  text-transform: uppercase;
}
.btn-black:hover {
  background-color: #111;
  border-color: transparent;
  box-shadow: inset 0 0 0 20rem rgba(17, 17, 17, 0.1);
  text-decoration: none;
}
.btn-black:active {
  background-color: #039f97;
  border-color: transparent;
  box-shadow: inset 0 0 0 20rem rgba(0, 0, 0, 0.125), inset 0 3px 4px 0 rgba(0, 0, 0, 0.25), 0 0 1px rgba(0, 0, 0, 0.125);
  text-decoration: none;
}
.btn-black:focus {
  box-shadow: inset 0 0 0 20rem rgba(17, 17, 17, 0.1);
  outline: none;
}
.btn-black:disabled {
  background-color: rgba(17, 17, 17, 0.1);
  border-color: #aaaaaa;
}
.btn-black:disabled:hover {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  box-shadow: none;
  cursor: not-allowed;
}
.btn.disabled {
  border-color: #aaaaaa;
  color: #aaaaaa;
  background-color: rgba(17, 17, 17, 0.1);
  opacity: 0.5;
  font-weight: 600;
}
.btn.disabled:hover {
  background-color: rgba(17, 17, 17, 0.1);
  border-color: #aaaaaa;
  box-shadow: none;
  cursor: not-allowed;
}

.btn-actions {
  display: flex;
  justify-content: space-between;
}

.btn-rounded {
  border-radius: 50%;
  border: 1px solid #039f97;
  background: linear-gradient(90deg, #00a397 0, #039f97);
  display: flex;
  padding: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.btn-rounded:hover {
  transition: all 0.3s;
  text-decoration: none !important;
}
.btn-rounded-white {
  border: 2px solid #eaeaea;
  background: white;
  text-decoration: none !important;
  color: #585858;
}
.btn-rounded-white:hover {
  box-shadow: none;
}

.btn-rounded-red {
  border-radius: 50%;
  border: 1px solid #ff5046;
  background: #ff5046;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-rounded-red:hover {
  cursor: pointer;
  text-decoration: none;
}

.btn-none {
  border: none;
  position: relative;
  background: transparent;
}
.btn-none:hover {
  cursor: pointer;
  opacity: 0.5;
}
.btn-none:focus {
  outline: none;
}

.btn-file {
  padding: 0.5rem 1rem;
  background: #f5f5f5;
}
.btn-file:hover {
  background: rgba(17, 17, 17, 0.1);
}
.btn-file span {
  color: #585858;
  font-weight: 300;
}

@media (max-width: 768px) {
  .btn-responsive-100 {
    width: 100% !important;
    text-align: center;
  }
}
@media print {
  .btn {
    display: none;
  }
}
.back-button {
  padding: 0;
  font-size: 10px;
}

.card-btn {
  border: none;
  background: transparent;
  font-family: "Open Sans", sans-serif;
  color: #707070;
}
.card-btn:hover {
  cursor: pointer;
}
.card-btn:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.cancel-button {
  position: absolute;
  top: 4.5rem;
  right: 2.5rem;
}

@media (max-width: 480px) {
  .cancel-button {
    top: 3.3rem;
    right: 1.3rem;
  }
}
.btn-group {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 500px) {
  .btn-group {
    flex-direction: column-reverse;
  }
  .btn-group .btn {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    margin-left: 0;
  }
}
.filter-button {
  box-shadow: -2px 5px 10px rgba(0, 0, 0, 0.1);
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.img-user {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.img-user img {
  width: 50px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .mobile-img-user {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
  }
  .mobile-img-user .zoom-img {
    width: 70px;
    height: auto;
  }
}
.user-photo-edit {
  width: 102px;
  height: 102px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.user-photo-edit img {
  width: 102px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.img-user-72 {
  width: 72px;
  height: 72px;
}
.img-user-72 img {
  width: 72px;
}

.space-weekday {
  margin-top: 2rem;
}
.space-weekday:nth-child(1) {
  margin-top: 0;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.saving span {
  -webkit-animation-name: blink;
          animation-name: blink;
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  font-size: 22px;
}
.saving span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.saving span:nth-child(3) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.left-animation-enter {
  transform: translateX(-100%);
}

.left-animation-enter-active {
  transform: translateX(0%);
  transition: all 0.25s ease;
}

.left-animation-exit {
  transform: translateX(0%);
}

.left-animation-exit-active {
  transform: translateX(-100%);
  transition: all 0.25s ease;
}

.flex-basic {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(33% - 1em);
}

.card table {
  width: 100% !important;
}

.flex-100 {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(100% - 1em);
}

.flex-50 {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(50% - 1em);
}

.flex-75 {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(75% - 1em);
}

.card-deck {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.card-deck .ticket-card p.card-head-small,
.card-deck .ticket-card div.card-head-small {
  padding-left: 17px;
  margin-top: 1rem;
  font-size: 14px;
}
.card-deck .card,
.card-deck .card-100,
.card-deck .ticket-card {
  color: #313131;
  transition: all 0.2s;
  border-radius: 3px;
  box-shadow: none;
  margin: 10px 0.4rem;
  width: 100%;
  border: 0.5rem solid #f5f5f5;
  margin-top: 5px;
  color: #707070;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.01);
}
.card-deck .card:hover,
.card-deck .card-100:hover,
.card-deck .ticket-card:hover {
  transition: all 0.2s;
  box-shadow: 0 4px 6px 0 rgba(17, 17, 17, 0.1);
}
.card-deck .card-head,
.card-deck .card-100-head,
.card-deck .ticket-card-head {
  padding: 10px 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.card-deck .card-head-action-button,
.card-deck .card-100-head-action-button,
.card-deck .ticket-card-head-action-button {
  position: absolute;
  right: 1rem;
}
.card-deck .card-head-small,
.card-deck .card-100-head-small,
.card-deck .ticket-card-head-small {
  font-size: 11px;
  margin-top: 0;
  margin-bottom: 10px;
}
.card-deck .card-head-title,
.card-deck .card-100-head-title,
.card-deck .ticket-card-head-title {
  color: #a7985f;
  font-weight: bold;
  font-size: 16px;
}
.card-deck .card-head-description,
.card-deck .card-100-head-description,
.card-deck .ticket-card-head-description {
  margin-top: 0;
}
.card-deck .card-head-value,
.card-deck .card-100-head-value,
.card-deck .ticket-card-head-value {
  width: 50%;
  font-size: 22px;
  margin-top: 1.2rem;
  text-align: right;
}
.card-deck .card-head-value p,
.card-deck .card-100-head-value p,
.card-deck .ticket-card-head-value p {
  margin: 0;
}
.card-deck .card-head-value span,
.card-deck .card-100-head-value span,
.card-deck .ticket-card-head-value span {
  font-size: 14px;
}
.card-deck .card-body,
.card-deck .card-100-body,
.card-deck .ticket-card-body {
  padding: 0 1rem;
  position: relative;
  font-size: 15px;
}
.card-deck .card-body-item,
.card-deck .card-100-body-item,
.card-deck .ticket-card-body-item {
  margin-top: 5px;
}

@media (min-width: 1200px) {
  .card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 1em);
  }

  .card-100 {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(100% - 1em);
  }

  .flex-100 {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(100% - 1em);
  }

  .ticket-card {
    width: 100%;
  }

  .card-link {
    width: 48%;
    margin: 5px;
  }
}
@media (max-width: 1200px) {
  .card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 1em);
  }
}
@media (max-width: 800px) {
  .card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 1rem);
  }

  .flex-basic {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 1em);
  }
}
@media (max-width: 768px) {
  .card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 1em);
  }
}
@media (max-width: 480px) {
  .card {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(100% - 1em);
  }

  .flex-basic {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(100% - 1em);
  }
}
.pagination {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
  list-style: none;
}
.pagination li:hover {
  cursor: pointer;
}
.pagination li.active a {
  background: #039f97;
  color: #fff;
}
.pagination li a {
  width: 25px;
  height: 25px;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  color: #313131;
}
.pagination li a:hover {
  box-shadow: 0 4px 6px 0 rgba(17, 17, 17, 0.1);
}
.pagination li:last-child {
  margin-right: 0;
}

.card-tag {
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 10px;
  background: #f5f5f5;
  border-radius: 3px;
  text-align: center;
  padding: 5px;
  flex: 1 0 40px;
  flex-wrap: wrap;
  display: flex;
  height: 100%;
}
.card-tag:hover {
  opacity: 1;
  cursor: default;
}
.card-tag span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-border {
  border: 1px solid #f5f5f5;
}

.card-default-border {
  border: 8px solid #f5f5f5;
  border-radius: 3px;
}

.card-highlight {
  border-color: #efebd8 !important;
  background: #fdf1d0;
}

.card-focus {
  -webkit-animation: fade 2s linear;
          animation: fade 2s linear;
}

@-webkit-keyframes fade {
  0% {
    border-color: #039f97;
  }
  100% {
    opacity: #f5f5f5;
  }
}

@keyframes fade {
  0% {
    border-color: #039f97;
  }
  100% {
    opacity: #f5f5f5;
  }
}
.card-negative {
  padding: 20px;
  margin-bottom: 15px;
  background: #efebd8;
}

.highlight-border {
  border-color: rgba(167, 152, 95, 0.3) !important;
}

.file-name {
  background: #f5f5f5;
  margin-left: 0.5rem;
}
.file-name p {
  margin: 0;
}
.file-name .delete-file {
  overflow-x: hidden;
}
.file-name:hover .delete-file {
  opacity: 1;
  transform: translateX(0px);
}
.file-name .download-file {
  overflow-x: hidden;
}
.file-name:hover .download-file {
  opacity: 1;
  transform: translateX(0px);
}

.input-file-progress {
  height: 4px !important;
  border-radius: 0 0 5px 5px;
}
.input-file-progress .p-progressbar-value {
  background: #039f97 !important;
}
.input-file-progress .p-progressbar-value-animate {
  transition: none !important;
}
.input-file-progress .p-progressbar-label {
  color: #f5f5f5 !important;
}

.delete-file {
  background: #e6646e;
  color: #fff;
  padding: 0.5rem;
  /*position: relative;
  left: 25px;*/
  transform: translateX(20px);
  opacity: 0;
  border-radius: 0 5px 0 0;
}
.delete-file:hover {
  background: #ff5046;
}

.download-file {
  background: #208fdc;
  color: #fff;
  padding: 0.5rem;
  /*position: relative;
  left: 25px;*/
  transform: translateX(20px);
  opacity: 0;
  border-radius: 0 5px 0 0;
}
.download-file:hover {
  background: #039f97;
}

.flex-list {
  display: flex;
}
.flex-list .flex-calendar {
  position: relative;
  width: 385px;
}
.flex-list .flex-calendar .content {
  flex-wrap: wrap;
}
.flex-list .panel .panel-body .content:last-child {
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .flex-list {
    display: block;
  }
  .flex-list .lessons-calendar {
    width: 100%;
  }
  .flex-list .lessons-calendar .p-datepicker-calendar div {
    margin: auto;
  }
  .flex-list .grades-text {
    margin-top: 50px;
  }
  .flex-list .flex-calendar {
    margin-bottom: 80px;
    width: unset;
  }
  .flex-list .flex-calendar .content {
    width: 100%;
    justify-content: center;
  }
  .flex-list .panel {
    margin-top: 80px;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 500px) {
  .card-basic-header-date {
    position: inherit !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
    align-items: center;
  }
}
.card-deck {
  display: flex;
  flex-wrap: wrap;
}
.card-deck .card-base {
  border: 1px solid #111;
  border-radius: 3px;
  margin: 10px 0;
  position: relative;
  transition: ease all 0.5s;
  margin-right: 1rem;
  color: #313131;
}
.card-deck .card-base:hover {
  box-shadow: 5px 7px 8px 1px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.5s;
}
.card-deck .card-base .card-status {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: #fff;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.card-deck .card-base .card-basic {
  height: 100%;
  padding: 15px;
  padding-top: 10px;
}
.card-deck .card-base .card-basic-header-title {
  font-size: 18px;
  letter-spacing: 1px;
}
.card-deck .card-base .card-basic-header-sub-title {
  font-size: 14px;
  font-style: italic;
  color: #aaaaaa;
}
.card-deck .card-base .card-basic-header-date {
  position: absolute;
  right: 10px;
  top: 40px;
  font-size: 14px;
}
.card-deck .card-base .card-basic-header-date p {
  margin: 0;
  font-weight: 600;
}
.card-deck .card-base .card-basic-header-date span {
  color: #5f5f5f;
}
.card-deck .card-base .card-basic-line {
  width: 20%;
  margin-top: 1rem;
  margin-bottom: 0;
  border-width: 3px;
  border-color: #039f97;
}
.card-deck .card-base .card-basic-body-content {
  margin-top: 1rem;
}
.card-deck .card-base .card-basic-body-content-title {
  font-weight: 600;
  min-width: 150px;
  margin-right: 1rem;
  margin-bottom: 2px;
}
.card-deck .card-base .card-basic-body-content-text {
  margin-top: 2px;
}
.card-deck .card-base .card-basic-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  align-items: flex-end;
}
.card-deck .card-base .card-basic-footer .card-basic-body-content-text {
  margin: 0.5rem;
  font-weight: 600;
}
.card-deck .card-base .card-basic-footer .card-basic-body-content-text span {
  font-weight: 400;
}

@media only screen and (min-width: 1655px) {
  .card-base {
    width: 32%;
  }
}
@media only screen and (max-width: 1655px) {
  .card-base {
    width: 48%;
  }
}
@media only screen and (max-width: 1210px) {
  .card-base {
    width: 100%;
  }
}
.card-render-buttons {
  position: absolute;
  right: 30px;
}

.study-material-filter {
  float: right;
}

.material-card.card-head {
  display: block;
}

@media (max-width: 1200px) {
  .study-material-filter {
    float: none;
    margin-top: 30px;
    padding-left: 0;
  }
}
.badge {
  color: white;
  border: none;
  position: absolute;
  left: -8px;
  top: 0;
  padding: 5px;
  font-size: 12px;
  transition: all 0.2s ease-in;
  height: 25px;
  overflow: hidden;
  white-space: nowrap;
  z-index: 1020;
}
.badge strong {
  display: flex;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.badge strong span.small-inf {
  font-size: 10px;
  font-weight: 400;
  margin-left: 10px;
  opacity: 0;
  transition: all 0.1s ease-in;
}
.badge:hover {
  width: 100% !important;
  height: 100%;
  opacity: 0.9;
  transition: all 0.2s ease-in;
  font-size: 20px;
}
.badge:hover span.small-inf {
  opacity: 1;
  transition: all 0.1s ease-in;
}

.badge-background {
  width: 100%;
  height: 25px;
  position: absolute;
  z-index: 1010;
  top: 0;
  left: 0;
  background: #f5f5f5;
}

.grades-head-card {
  width: 100%;
  background: #fdf1d0;
  color: #a7985f;
  padding: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.grades-head-card:hover {
  opacity: 1;
  color: #7d7140;
  background: #efebd8;
}

.badge-ball {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .grade-mobile-card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 1rem;
  }
  .grade-mobile-card p {
    margin-top: 1rem;
  }
}
.message-review,
.message-answer {
  border: 1px solid #dddddd;
  padding: 15px;
  box-shadow: 0 4px 6px 0 rgba(17, 17, 17, 0.1);
}

.message-file {
  display: block;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background: #f5f5f5;
  color: #313131;
  padding: 15px;
}
.message-file:hover {
  cursor: pointer;
}

.message-answer {
  background: #efebd8;
  width: 100%;
  min-height: 150px;
}

.ticket {
  margin-bottom: 10px;
  width: 100%;
}
.ticket .card-head {
  padding: 0;
}
.ticket .card-body {
  padding: 0;
}

.message {
  border-radius: 0;
}
.message:hover {
  border-color: rgba(17, 17, 17, 0.1);
}
.message-read {
  background: #f5f5f5;
}
.message-body {
  border-radius: 0;
  background: #fafafa;
  padding: 2rem;
}

.message-bubble {
  padding: 0.5rem;
}
.message-bubble-date {
  color: #aaaaaa;
}
.message-bubble-file {
  padding: 0.5rem 1rem;
}
.message-bubble-file span {
  color: #585858;
  font-weight: 300;
}
.message-bubble-content {
  padding: 0.5rem;
}
.message-bubble-content.my-message {
  background: #fff;
}
.message-bubble-content.my-message .message-bubble-user {
  color: #039f97;
}
.message-bubble-content.my-message .message-bubble-file {
  background: #eaeaea;
}
.message-bubble-content.my-message .message-bubble-file:hover {
  background: #dddddd;
}
.message-bubble-content.default-message {
  background: #efebd8;
}
.message-bubble-content.default-message .message-bubble-user {
  color: #a7985f;
}
.message-bubble-content.default-message .message-bubble-file {
  background: #fafafa;
}
.message-bubble-content.default-message .message-bubble-file:hover {
  background: #f5f5f5;
}

.loading-indicator {
  color: #039f97;
  font-size: 22px;
}

.centered {
  position: fixed;
  top: 50%;
  left: calc(130px + 50%);
}

@media only screen and (max-width: 1200px) {
  .centered {
    left: calc(40px + 50%);
  }
}
@media only screen and (max-width: 768px) {
  .centered {
    left: 46% !important;
    top: calc(50% + 61px);
  }
}
.tabs-nav {
  width: 100%;
  display: inline-flex;
  text-align: right;
  list-style: none;
  justify-content: flex-end;
  flex: 1 1;
}
.tabs-nav li {
  padding: 10px 10px;
  text-align: right;
}
.tabs-nav li:first-child {
  padding-left: 0;
}
.tabs-nav a {
  color: rgba(17, 17, 17, 0.6);
  cursor: pointer;
}
.tabs-nav a.tab--active {
  border-bottom: 2px solid #039f97;
  font-weight: 600;
  color: rgba(17, 17, 17, 0.9);
}
.tabs-nav a:not(.tab--active) {
  border-bottom: 1px solid transparent;
  color: rgba(17, 17, 17, 0.6);
}

@media screen and (max-width: 768px) {
  .tabs-nav {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px;
    text-align: center;
    flex-wrap: wrap;
    justify-content: initial;
  }
  .tabs-nav li {
    padding: 15px 6px;
    width: 155px;
  }
  .tabs-nav a:not(.tab--active) {
    border-bottom: 1px solid rgba(17, 17, 17, 0.1);
  }
}
.dropdown-menu-button {
  margin-right: 0;
}

.dropdown-menu {
  padding: 5px;
  background-color: white;
  border: 1px solid #f5f5f5;
  padding: 0;
  margin-top: 2px;
  position: absolute;
  right: 0;
  z-index: 999;
  margin-right: 0;
}
.dropdown-menu .dropdown-menu-item {
  list-style: none;
  text-align: right;
  margin: 3px 0;
  padding: 0;
  background-color: #fff;
}
.dropdown-menu .dropdown-menu-item button,
.dropdown-menu .dropdown-menu-item a {
  width: 100%;
  text-align: left;
  border: none;
  font-size: 13px;
  padding: 5px 10px 5px 20px;
  cursor: pointer;
  background-color: #fff;
  color: #313131;
}
.dropdown-menu .dropdown-menu-item a,
.dropdown-menu .dropdown-menu-item a:visited {
  text-decoration: none;
  display: inline-block;
}
.dropdown-menu .dropdown-menu-item:hover button,
.dropdown-menu .dropdown-menu-item:hover a {
  background-color: rgba(17, 17, 17, 0.1);
}

.no-dropdown {
  width: 100%;
  border: none;
  box-shadow: none;
}
.no-dropdown svg {
  display: none;
}
.no-dropdown button {
  width: 100%;
}
.no-dropdown button svg {
  display: block;
}

.cronograma-dropdown-item {
  right: 10px !important;
}
.cronograma-dropdown-item .cronograma-item {
  right: 0;
  width: 200px;
}

.warning-card {
  padding: 20px;
  border-radius: 6px;
  font-size: 15px;
  color: #707070;
  font-weight: 600;
}

.warning-card--info {
  background-color: #efebd8;
}

.warning-card--success {
  background-color: #74b777;
  color: white;
}

.warning-card--warning {
  background-color: #b77474;
  color: white;
}

.teaching-plan-title {
  color: #585858;
  line-height: 1;
}
.teaching-plan-text {
  color: #585858;
  font-weight: bolder;
}
.teaching-plan-syllabus {
  color: #585858;
  margin-top: -12px;
}

.action-button-title {
  display: flex;
}
.action-button-title .fa-icon {
  color: #039f97;
  font-size: 18px;
  float: left;
  margin-top: 17px;
  height: 25px;
}
.action-button-title .action-button-text {
  color: #707070;
  font-weight: bolder;
  text-decoration: underline;
  font-size: 18px;
  float: left;
  margin-left: 5px;
}

.link-label {
  display: flex;
}
.link-label .fa-icon {
  color: #039f97;
  font-size: 18px;
  float: left;
  height: 25px;
}
.link-label .centered-icon {
  margin-left: 50%;
  transform: translate(-50%);
}
.link-label .text {
  color: #707070;
  font-weight: bolder;
  text-decoration: underline;
  font-size: 14px;
  float: left;
  margin-left: 5px;
}
.link-label .text p {
  margin: 0;
}

.title-text-content-page-title {
  color: #707070;
  line-height: 2;
  font-weight: bolder;
  font-size: 18px;
}
.title-text-content-title {
  color: #585858;
  line-height: 2;
  font-weight: bolder;
}
.title-text-content-text {
  color: #585858;
  line-height: 1.5;
}

.stepper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
.stepper .line {
  position: absolute;
  top: 9px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: calc(100% - 5.5rem);
  height: 1px;
  background: rgba(17, 17, 17, 0.1);
  border: 1px solid rgba(17, 17, 17, 0.1);
}
.stepper .step {
  text-align: center;
  width: 100px;
  z-index: 1;
}
.stepper .step:hover {
  cursor: pointer;
}
.stepper .step p {
  font-size: 12px;
  color: #313131;
}
.stepper .step--icon {
  height: 18px;
  width: 18px;
  margin: auto;
  background: #f5f5f5;
  border: 1px solid #d0d0d0;
  z-index: 1;
  border-radius: 50%;
}

.tree-view-section {
  width: 100%;
  font-size: 16px;
  width: 100%;
  list-style: none;
}
.tree-view-section:hover {
  cursor: pointer;
}
.tree-view-section-icon {
  transition: transform 0.2s;
}
.tree-view-section-icon:hover {
  /* IE 9 */
  /* Safari 3-8 */
  transform: scale(1.1);
  color: #a7985f;
}

.tree-view-item {
  font-size: 15px;
  padding: 0.5rem;
}
.tree-view-item::nth-last-child() {
  border-bottom: 1px solid #fff;
}

.tree-parent {
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: 20px;
  font-weight: 600;
}

.tree-subareas {
  font-size: 18px;
  font-weight: 400;
}
.tree-subareas p {
  border-bottom: 1px solid #fff;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.tree-domains {
  font-size: 16px;
}

.table-student-hours .rt-thead.-header {
  color: #a7985f;
  font-weight: 600;
  display: flex;
  height: 35px;
}

.table-student-hours .rt-tr {
  height: auto;
  text-align: center;
}

.table-student-hours .rt-tbody .-even {
  background: #f8f8f8;
}

.table-student-hours .rt-tbody .rt-td {
  color: #585858;
}

.table-student-hours .rt-tbody .rt-tr-group:nth-child(2):after {
  content: "15 minutos de intervalo";
  color: #585858;
  height: auto;
  background-color: #efebd8;
  text-align: center;
  align-items: center;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table-student-hours .rt-tbody .rt-tr-group:nth-child(4):after {
  content: "15 minutos de intervalo";
  color: #585858;
  height: auto;
  background-color: #efebd8;
  text-align: center;
  align-items: center;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table-student-hours .rt-tbody .rt-tr-group:nth-child(8):after {
  content: "15 minutos de intervalo";
  color: #585858;
  height: auto;
  background-color: #efebd8;
  text-align: center;
  align-items: center;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table-student-hours .rt-tbody .rt-tr-group:nth-child(10):after {
  content: "15 minutos de intervalo";
  color: #585858;
  height: auto;
  background-color: #efebd8;
  text-align: center;
  align-items: center;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table-student-hours .rt-tbody .rt-tr-group:nth-child(14):after {
  content: "15 minutos de intervalo";
  color: #585858;
  height: auto;
  background-color: #efebd8;
  text-align: center;
  align-items: center;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table-student-hours .rt-tbody .rt-tr-group:nth-child(6) {
  border-bottom: solid 15px #d9d9d9;
}

.sheet-table {
  display: block;
}
.sheet-table .rt-tr-group {
  page-break-inside: avoid;
  font-size: 12px;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
body {
    width: 210mm;
    height: 297mm;
  }
  html .sheet-table-responsive,
body .sheet-table-responsive {
    display: none;
  }
  html .sheet-table,
body .sheet-table {
    display: block;
  }
  html .sheet-table .rt-thead.-header,
body .sheet-table .rt-thead.-header {
    color: #585858;
    display: block;
  }
  html .sheet-table .rt-tbody,
body .sheet-table .rt-tbody {
    display: table;
  }
  html .sheet-table .rt-tbody .rt-tr-group,
body .sheet-table .rt-tbody .rt-tr-group {
    display: table-row-group;
  }
  html .sheet-table .rt-tbody .rt-tr-group:nth-child(2):after,
body .sheet-table .rt-tbody .rt-tr-group:nth-child(2):after {
    display: none;
  }
  html .sheet-table .rt-tbody .rt-tr-group:nth-child(4):after,
body .sheet-table .rt-tbody .rt-tr-group:nth-child(4):after {
    display: none;
  }
  html .sheet-table .rt-tbody .rt-tr-group:nth-child(8):after,
body .sheet-table .rt-tbody .rt-tr-group:nth-child(8):after {
    display: none;
  }
  html .sheet-table .rt-tbody .rt-tr-group:nth-child(10):after,
body .sheet-table .rt-tbody .rt-tr-group:nth-child(10):after {
    display: none;
  }
  html .sheet-table .rt-tbody .rt-tr-group:nth-child(14):after,
body .sheet-table .rt-tbody .rt-tr-group:nth-child(14):after {
    display: none;
  }
  html .sheet-table .rt-tbody .rt-tr-group:nth-child(6) .rt-tr,
body .sheet-table .rt-tbody .rt-tr-group:nth-child(6) .rt-tr {
    page-break-after: always;
    page-break-before: always;
  }
  html .sheet-table .rt-tbody .rt-tr-group:nth-child(11) .rt-tr,
body .sheet-table .rt-tbody .rt-tr-group:nth-child(11) .rt-tr {
    page-break-after: always;
    page-break-before: always;
  }
  html .sheet-table .rt-tbody .rt-tr-group .rt-tr,
body .sheet-table .rt-tbody .rt-tr-group .rt-tr {
    page-break-inside: avoid;
    display: table-row;
  }
  html .sheet-table .rt-tbody .rt-tr-group .rt-tr .rt-td,
body .sheet-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
    display: table-cell;
  }
}
@media (min-width: 1280px) {
  .sheet-table-responsive {
    display: none;
  }
}
@media (max-width: 1280px) {
  .sheet-table {
    display: none;
  }

  .table-student-hours .rt-thead.-header {
    display: none;
  }
}
@media (max-width: 720px) {
  .sheet-table {
    display: none;
  }

  .table-student-hours .rt-thead.-header {
    display: none;
  }
}
@media (max-width: 480px) {
  .sheet-table {
    display: none;
  }

  .table-student-hours .rt-thead.-header {
    display: none;
  }
}
@media (max-width: 300px) {
  .sheet-table {
    display: none;
  }

  .table-student-hours .rt-thead.-header {
    display: none;
  }
}
.collapse-table {
  border: none;
  height: 100%;
  border-radius: 3px;
}
.collapse-table .btn-none:hover {
  opacity: 1 !important;
}
.collapse-table-header {
  box-shadow: 0 4px 6px 0 rgba(17, 17, 17, 0.1);
  border: 0.5rem solid #f5f5f5;
  padding: 1rem;
  background: #fff;
  border-radius: 3px 3px 0 0;
  position: relative;
  color: #a7985f;
  font-size: 13px;
  font-weight: 500;
}
.collapse-table-body {
  color: #585858;
  position: relative;
  border-top: 1px solid rgba(17, 17, 17, 0.1);
}
.collapse-table .collapse-hour {
  border-left: 1px solid rgba(17, 17, 17, 0.1);
  border-right: 1px solid rgba(17, 17, 17, 0.1);
  border-bottom: 1px solid rgba(17, 17, 17, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
}
.collapse-table .collapse-data {
  border-right: 1px solid rgba(17, 17, 17, 0.1);
  border-bottom: 1px solid rgba(17, 17, 17, 0.1);
  background-color: #f5f5f5;
}

.btn-hour--is-active, .btn-hour {
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #707070;
  font-weight: 600;
  font-size: 15px;
}

.btn-none .card-deck:hover {
  cursor: pointer;
  opacity: none;
}
.btn-none .card-deck:hover:focus {
  outline: none;
}

.semi-bold-15 {
  font-weight: 600;
  font-size: 15px;
}

.semi-bold-18 {
  font-weight: 600;
  font-size: 18px;
}

.schedule-cards .max-width-900 {
  max-width: 900px;
}

@media (min-width: 1280px) {
  .btn-hour {
    margin-right: 1rem;
    position: relative;
    background: transparent;
  }
}
@media (max-width: 820px) {
  .card-deck .adjust--is-mobile {
    justify-content: end;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .card-deck .mt-adjust--is-mobile {
    margin-top: 3px;
  }
  .card-deck .mt-adjust2--is-mobile {
    margin-top: -30px;
  }
  .card-deck .d-flex-wrap--is-mobile {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
  }
  .card-deck .text-left {
    text-align: left;
  }
  .card-deck .mr-0--is-mobile {
    margin-right: 0;
  }
  .card-deck .semi-bold-15 {
    font-weight: 600;
    font-size: 15px;
  }
  .card-deck .semi-bold-18 {
    font-weight: 600;
    font-size: 18px;
  }

  .btn-hour {
    margin-right: 0px;
    margin-left: 0px;
  }
}
.btn-hour {
  background: #eaeaea;
  border-radius: 4px;
  padding: 0 2rem;
  position: relative;
  border: none;
  cursor: pointer;
}
.btn-hour--is-active {
  background: #039f97;
  color: #fff;
  border-radius: 4px;
  padding: 0 2rem;
  position: relative;
  border: none;
}
.btn-hour--is-active:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.declaration-template-list-card .max-width {
  max-width: 930px;
}
.declaration-template-list-card .width-300 {
  width: 300px;
}
.declaration-template-list-card .description-min {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  max-height: 45px;
}

@media (max-width: 1024px) {
  .declaration-template-list-card .max-width {
    max-width: 700px;
  }
}
@media (max-width: 820px) {
  .mt-1--is-mobile {
    margin-top: 0.5rem;
  }

  .mb-1--is-mobile {
    margin-bottom: 0.5rem;
  }

  .justify-center--is-mobile {
    justify-content: center;
  }

  .d-flex-wrap--is-mobile {
    display: flex;
    flex-wrap: wrap;
  }

  .declaration-template-list-card .description-min {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
    max-height: 45px;
  }
}
.school-record .rt-thead.-header {
  color: #a7985f;
  font-weight: 600;
  display: flex;
  height: 35px;
}
.school-record .rt-tr {
  padding-top: 2px;
}
.school-record .rt-tbody .-even {
  background: #f8f8f8;
}
.school-record .rt-tbody .rt-td {
  color: #707070;
  white-space: unset;
}
.school-record .rt-thead.-headerGroups {
  background-color: #efebd8 !important;
  color: #a39161;
  font-weight: 600;
  font-size: 15px;
  height: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  text-transform: uppercase;
}
.school-record .rt-noData {
  margin-top: 5%;
}

.school-record-without-term .rt-thead.-header {
  color: #a7985f;
  font-weight: 600;
  display: flex;
  height: 35px;
}
.school-record-without-term .rt-tr {
  height: auto;
}
.school-record-without-term .rt-tbody .-even {
  background: #f8f8f8;
}
.school-record-without-term .rt-tbody .rt-td {
  color: #707070;
  white-space: unset;
}
.school-record-without-term .rt-thead.-headerGroups {
  background-color: #fff !important;
  color: #a39161;
  font-weight: 600;
  font-size: 15px;
  height: auto;
}

.school-record-complementary-activities .rt-thead.-header {
  color: #a7985f;
  font-weight: 600;
  display: flex;
  height: 35px;
}
.school-record-complementary-activities .rt-tr {
  height: auto;
}
.school-record-complementary-activities .rt-tbody .-even {
  background: #f8f8f8;
}
.school-record-complementary-activities .rt-tbody .rt-td {
  color: #707070;
  white-space: unset;
}
.school-record-complementary-activities .rt-thead.-headerGroups {
  display: none;
}

.school-record-responsive-table {
  display: none;
}

@media (max-width: 1100px) {
  .school-record-table {
    display: none;
  }

  .school-record-responsive-table {
    display: inline;
    display: initial;
  }

  .school-record .rt-thead.-header {
    display: none;
  }
}
@media (max-width: 720px) {
  .school-record-table {
    display: none;
  }

  .school-record .rt-thead.-header {
    display: none;
  }
}
@media (max-width: 480px) {
  .school-record-table {
    display: none;
  }

  .school-record .rt-thead.-header {
    display: none;
  }
}
@media (max-width: 300px) {
  .school-record-table {
    display: none;
  }

  .school-record .rt-thead.-header {
    display: none;
  }
}
.school-record-collapse {
  background: #efebd8;
  border: none;
  height: 100%;
  border-radius: 3px;
}
.school-record-collapse-header {
  padding: 1rem;
  background: #efebd8;
  border-radius: 3px 3px 0 0;
  position: relative;
  border: none;
  color: #a39161;
  font-size: 13px;
  font-weight: 500;
}
.school-record-collapse-content {
  color: #707070;
  position: relative;
}
.school-record-collapse-body:nth-child(even) {
  background: #fff;
}
.school-record-collapse-body:nth-child(odd) {
  background: #f5f5f5;
}

.hours-footer {
  background: #efebd8;
  border: none;
  height: 100%;
  padding: 1rem;
  border-radius: 0 0 3px 3px;
  position: relative;
  color: #707070;
  font-size: 13px;
  font-weight: 500;
  text-align: right;
}

@media (max-width: 820px) {
  .hours-footer .justify-start {
    justify-content: flex-start;
  }
}
.complementary-activities-collapse {
  background: #fff;
  border: none;
  height: 100%;
  border-radius: 3px;
}
.complementary-activities-collapse-header {
  padding: 1rem;
  background: #fff;
  border-radius: 3px 3px 0 0;
  position: relative;
  border: none;
  color: #707070;
  font-size: 13px;
  font-weight: 500;
}
.complementary-activities-collapse-content {
  color: #707070;
  position: relative;
}
.complementary-activities-collapse-body:nth-child(even) {
  background: #fff;
}
.complementary-activities-collapse-body:nth-child(odd) {
  background: #f5f5f5;
}

.student-card {
  word-wrap: break-word;
}

@media (min-width: 1280px) {
  .student-card-width {
    width: 400px;
  }
  .student-card-d-flex {
    display: flex;
  }
}
.card-grid .declaration-template-form-width {
  width: 40%;
}

@media (max-width: 820px) {
  .card-grid .declaration-template-form-width {
    width: 100%;
  }
}
.big-calendar-styles {
  cursor: default;
}
.big-calendar-styles .rbc-today {
  background-color: transparent;
}
.big-calendar-styles .rbc-toolbar {
  display: none;
}
.big-calendar-styles .rbc-toolbar .rbc-btn-group:last-child {
  display: none;
}
.big-calendar-styles .rbc-toolbar .rbc-toolbar-label {
  text-align: end;
}
.big-calendar-styles .rbc-row-content {
  display: none;
}
.big-calendar-styles .rbc-hour-header {
  font-size: 15px;
  color: #a39161;
  text-align: center;
  margin-top: -5px;
  font-weight: bold;
}
.big-calendar-styles .rbc-time-view .rbc-row {
  height: 45px;
}
.big-calendar-styles .rbc-time-view .rbc-row .rbc-header {
  font-size: 15px;
  color: #a39161;
  text-align: center;
  padding-top: 10px;
  pointer-events: none;
}
.big-calendar-styles .rbc-time-view .rbc-row .rbc-header:first-child {
  display: none;
}
.big-calendar-styles .rbc-time-view .rbc-row .rbc-today {
  background-color: #f5f3e7;
}
.big-calendar-styles .rbc-time-view .rbc-time-header-cell {
  box-shadow: 0 4px 6px 0 rgba(17, 17, 17, 0.1);
}
.big-calendar-styles .rbc-time-view .rbc-allday-cell {
  height: 20px;
}
.big-calendar-styles .rbc-time-header-content {
  border-left: none;
}
.big-calendar-styles .rbc-label {
  color: #585858;
}
.big-calendar-styles .rbc-timeslot-group {
  min-height: 150px;
  border-bottom: 1px solid #eaeaea;
}
.big-calendar-styles .rbc-day-slot .rbc-event {
  align-items: center;
  background-color: rgba(245, 245, 245, 0.8);
  color: #585858;
  border: none;
  height: 5.20833% !important;
  overflow: inherit;
}
.big-calendar-styles .rbc-day-slot .rbc-events-container {
  margin-right: 0;
}
.big-calendar-styles .rbc-day-slot .rbc-event-label {
  background-color: #efebd8;
  height: 25px;
  font-size: 14px;
  color: #585858;
  width: 100%;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}
.big-calendar-styles .rbc-day-slot .rbc-event-content {
  font-size: 13px;
  color: #585858;
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
}
.big-calendar-styles .rbc-time-column:nth-child(2) {
  display: none;
}
.big-calendar-styles .rbc-day-bg:first-child {
  display: none;
}
.big-calendar-styles .rbc-time-content {
  border-top: none;
}
.big-calendar-styles .rbc-time-content > * + * > * {
  border-left: 1px solid #eaeaea !important;
}
.big-calendar-styles .rbc-event {
  border-radius: 0px;
  padding: 0;
  cursor: default;
}
.big-calendar-styles .rbc-current-time-indicator {
  display: none;
}

.card-deck-grid-1 {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
}

.card-deck-grid-2 {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.card-deck-grid-3 {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.card-deck-grid-4 {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}

.card-grid {
  color: #313131;
  display: grid;
  transition: all 0.2s;
  border-radius: 3px;
  border: 0.5rem solid #f5f5f5;
  color: #707070;
  padding: 1rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.01);
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

@media (max-width: 1024px) {
  .card-deck-grid-4,
.card-deck-grid-3 {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .card-deck-grid-4,
.card-deck-grid-2,
.card-deck-grid-3 {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
}
.form-grid-1 {
  display: grid;
  grid-gap: 1rem 1rem;
  grid-template-columns: repeat(1, 1fr);
}
.form-grid-1.align-items-center {
  align-items: center;
}

.form-grid-2 {
  display: grid;
  grid-gap: 1rem 1rem;
  grid-template-columns: repeat(2, 1fr);
}
.form-grid-2.align-items-center {
  align-items: center;
}

.form-grid-3 {
  display: grid;
  grid-gap: 1rem 1rem;
  grid-template-columns: repeat(3, 1fr);
}
.form-grid-3.align-items-center {
  align-items: center;
}

.form-grid-4 {
  display: grid;
  grid-gap: 1rem 1rem;
  grid-template-columns: repeat(4, 1fr);
}
.form-grid-4.align-items-center {
  align-items: center;
}

.form-grid-1-2 {
  display: grid;
  grid-gap: 1rem 1rem;
  grid-template-columns: 2fr 1fr 1fr;
}

.form-grid-2-1 {
  display: grid;
  grid-gap: 1rem 1rem;
  grid-template-columns: 1fr 2fr;
}

.form-grid-2-1-2columns {
  display: grid;
  grid-gap: 1rem 1rem;
  grid-template-columns: 2fr 1fr;
}

.grid-last-cell--is-bigger {
  display: grid;
  grid-gap: 2rem 1rem;
  grid-template-columns: 1fr 1fr 1fr 1.1fr;
}

@media (max-width: 1024px) {
  .form-grid-4,
.form-grid-3 {
    display: grid;
    grid-gap: 1rem 1rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .form-grid-4.align-items-center,
.form-grid-3.align-items-center {
    align-items: center;
  }
}
@media (max-width: 768px) {
  .form-grid-1-2,
.form-grid-4,
.form-grid-2,
.form-grid-3 {
    display: grid;
    grid-gap: 1rem 1rem;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }
  .form-grid-1-2.align-items-center,
.form-grid-4.align-items-center,
.form-grid-2.align-items-center,
.form-grid-3.align-items-center {
    align-items: center;
  }
}
.pop-up.p-dialog .p-dialog-titlebar {
  background-color: white;
  border: none;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.pop-up.p-dialog .p-dialog-content {
  border: none;
  color: rgba(0, 0, 0, 0.54);
  height: calc(100% - 72px);
}
.pop-up.p-dialog .p-dialog-content .pop-up-content {
  overflow-y: scroll;
}
.pop-up.p-dialog .p-dialog-content .pop-up-content .pop-up-loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}
.pop-up.p-dialog .p-dialog-footer .btn-link {
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0;
  font-size: 15px;
  margin: 0;
}
.pop-up.p-dialog .p-dialog-footer .btn-add {
  font-weight: 600;
  font-size: 15px;
  margin: 0;
}
.pop-up.p-dialog .p-dialog-footer .btn-add span {
  color: #a9a9a9;
}

.pop-up-navigation {
  list-style: none;
  padding-left: 0;
}
.pop-up-navigation-item {
  position: relative;
}
.pop-up-navigation-item.active .pop-up-navigation-item-button {
  background: rgba(3, 159, 151, 0.2);
  color: #3f928b;
}
.pop-up-navigation-item-button {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  overflow-wrap: break-word;
  border: none;
  height: 100%;
  width: 100%;
  padding: 10px;
}
.pop-up-navigation-item-button:hover {
  background: #eaeaea;
}

@media (min-width: 720px) {
  .pop-up-navigation {
    grid-template-columns: repeat(auto-fill, 220px);
  }

  .pop-up {
    width: 720px;
  }
}
@media (min-width: 480px) {
  .pop-up-content {
    max-height: 500px;
  }
  .pop-up-content img {
    max-height: 500px;
    max-width: 100%;
    object-fit: contain;
  }
}
@media (max-width: 720px) {
  .pop-up-navigation {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .pop-up {
    width: calc(100% - 5rem);
  }
}
@media (max-width: 480px) {
  .pop-up {
    width: 100%;
    height: 100%;
  }

  .pop-up-content img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
.no-arrow-tooltip.__react_component_tooltip.place-left::after {
  border-left: 0 solid black !important;
}

.no-arrow-tooltip.__react_component_tooltip.place-right::after {
  border-right: 0 solid black !important;
}

.no-arrow-tooltip.__react_component_tooltip.place-top::after {
  border-top: 0 solid black !important;
}

.no-arrow-tooltip.__react_component_tooltip.place-bottom::after {
  border-bottom: 0 solid black !important;
}

.list-comparison {
  margin-top: 1rem;
}
.list-comparison-search {
  padding: 1rem;
}
.list-comparison-title {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  padding: 0.5rem;
  background: #039f97;
  color: white;
}
.list-comparison-container {
  margin-top: 1rem;
  overflow-y: scroll;
  height: 40rem;
}
.list-comparison-container.selected-container {
  height: 43.4rem;
}
.list-comparison-height {
  height: 40rem;
}
.list-comparison-view {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 1rem;
  border: none;
  background: transparent;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 300;
}
.list-comparison-view:nth-child(odd) {
  background: #eaeaea;
}
.list-comparison .is-icon {
  position: absolute;
  right: 10px;
  top: 0;
}
.list-comparison-button {
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding: 1rem;
  border: none;
  background: transparent;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 300;
}
.list-comparison-button:nth-child(odd) {
  background: #eaeaea;
}
.list-comparison-button:hover {
  cursor: pointer;
  background: #f8f8f8;
}

.list-comparasion-disabled .list-comparasion-container {
  height: 45.4rem;
}

.list-pendencies {
  border: 1px solid #eaeaea;
}
.list-pendencies-item {
  padding: 0.5rem;
  margin: 0;
}
.list-pendencies-item:nth-child(odd) {
  background: #eaeaea;
}

.cards-selector {
  margin-top: 1rem;
}
.cards-selector .cards-selector-container {
  border: 1px solid #dddddd;
  margin-top: 1rem;
  box-shadow: 5px 7px 8px 1px rgba(0, 0, 0, 0.15);
}
.cards-selector .cards-selector-container .card-selector {
  padding: 1rem;
}
.cards-selector .cards-selector-container .card-selector:nth-child(odd) {
  background: rgba(17, 17, 17, 0.1);
}

.tag {
  color: #fff;
  width: 20rem;
  text-align: center;
  font-weight: 600;
  padding: 0.3rem 1rem;
  border-radius: 15px;
  font-size: 12px;
  max-width: 160px;
}
.tag.green {
  background: #74b777;
}
.tag.red {
  background: #b77474;
}

.rt-td .tag {
  max-width: 100px;
}

.clean-tag {
  color: #fff;
  text-align: center;
  font-weight: 600;
  padding: 0.3rem 1rem;
  border-radius: 15px;
  font-size: 12px;
}
.clean-tag.green {
  background: #74b777;
}
.clean-tag.red {
  background: #b77474;
}

.warning {
  font-weight: 700;
  padding: 1rem;
  font-size: 14px;
  margin-bottom: 1rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.warning-primary {
  color: #fff;
  background: #039f97;
}
.warning-secondary {
  color: #707070;
  background: #efebd8;
}

.student-remessa-card {
  display: flex;
  justify-content: space-between;
}
.student-remessa-card .student-data {
  display: flex;
}
.student-remessa-card .student-remessa-paragraph {
  color: #a7985f;
  margin: 1rem 0 0 0;
  font-size: 15px;
  font-weight: 600;
}
.student-remessa-card .student-remessa-column {
  width: 100px;
}
.student-remessa-card .student-card-download-button {
  background: none;
  border: none;
  font-size: 26px;
  color: #313131;
  margin: 0;
  padding: 0;
}
.student-remessa-card .student-card-download-button:hover {
  color: #707070;
}
.student-remessa-user-img {
  width: 70px;
  height: 70px;
}
.student-remessa-user-img img {
  width: 70px;
  height: 70px;
}

@media (max-width: 500px) {
  .student-remessa-card {
    flex-direction: column;
    margin-left: 0;
  }
  .student-remessa-card .student-data {
    flex-direction: column;
    align-items: center;
  }
  .student-remessa-card .student-remessa-paragraph {
    margin-left: 0;
  }
  .student-remessa-card .student-remessa-column {
    margin-right: 0 !important;
    margin-bottom: 1rem;
  }
  .student-remessa-card .student-card-download-button {
    margin-top: 1rem;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dddddd;
    width: 100%;
    background: #039f97;
    color: white;
  }
}
@media (max-width: 480px) {
  .remessa-detail > section:first-child {
    display: grid;
    grid-row-gap: 10px;
    row-gap: 10px;
    justify-content: flex-start;
  }
}
@media (min-width: 480px) {
  .remessa-detail > section:first-child {
    display: flex;
    justify-content: space-between;
  }
}
.ficha-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ficha-details .ficha-aluno {
  display: flex;
  align-items: center;
}
.ficha-details .ficha-aluno .img-user {
  min-height: 50px;
  min-width: 50px;
}
.ficha-details .ficha-totals {
  display: grid;
  grid-gap: 1rem 1rem;
  grid-template-columns: repeat(3, 1fr);
}
.ficha-details .ficha-devendo .teaching-plan-text p {
  color: #d42333;
}

.grid-gap-3 {
  grid-gap: 1rem 3rem;
}

@media (max-width: 768px) {
  .ficha-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    -webkit-margin-before: 0.5rem;
            margin-block-start: 0.5rem;
    -webkit-margin-after: 0.5rem;
            margin-block-end: 0.5rem;
  }
  .ficha-details .ficha-aluno {
    grid-column: 1/-1;
  }
  .ficha-details .ficha-totals {
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: repeat(3, 1fr);
  }
}
.lancamento-details .lancamento-details-row:not(:first-child) {
  margin-top: 20px;
}

.selected-area {
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #c7c7c7;
  padding: 20px;
  height: calc(100% + 20px);
  width: calc(100% + 20px);
  top: 0;
  bottom: 0;
  right: 0;
  left: -20px;
}

.usuario-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #f5f5f5;
  padding: 1rem 2rem;
  border: 2px #f3f3f3 solid;
}
.usuario-card:nth-child(odd) {
  background: white;
}

.health-data-input .input-label-style {
  margin-left: 20px;
}

.health-data-input-min .input-label-style {
  margin-left: 10px;
}

.chat-popup-button {
  position: fixed;
  bottom: 15px;
  right: -40px;
  background: #a7985f;
  cursor: pointer;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 7;
  box-shadow: 0 0 20px #a39161;
  transition: 0.4s ease-in-out;
  -webkit-animation: 0.4s ease-in-out;
          animation: 0.4s ease-in-out;
  border: none;
  line-height: 20px;
  padding: 6px 6px;
  color: white;
  display: flex;
  align-items: center;
}
.chat-popup-button i {
  font-size: 25px;
  color: #fff;
}
.chat-popup-button span {
  font-weight: 600;
  font-size: 12px;
}
.chat-popup-button:hover {
  transition: 0.2s ease-in-out;
  -webkit-animation: 0.2s ease-in-out;
          animation: 0.2s ease-in-out;
  background: #a7985f;
  box-shadow: 0 0 20px #a39161;
  right: 0px;
}

.chat-popup-counter {
  position: absolute;
  top: -6px;
  left: -6px;
  padding: 5px;
  font-size: 12px;
  margin: 0;
  border-radius: 50px;
  background: #db6969;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 9px;
  font-weight: 600;
  text-align: center;
  z-index: 8;
}

.__talkjs_launcher {
  background-color: #033f7b !important;
  color: #fff !important;
}

.chat-conversation-users {
  position: fixed;
  right: 20px;
  top: 10px;
  z-index: 7;
  height: 500px;
  background: #f9f9f9;
  width: 350px;
  border-radius: 5px;
  border: 1px solid #dce0e0;
}
.chat-conversation-users-header {
  height: 62px;
  padding: 10px;
  background: #f1f2f2;
  border-bottom: 1px solid #dce0e0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-conversation-users-header button {
  border: none;
  background: transparent;
  transition: color 0.2s;
}
.chat-conversation-users-header button:hover {
  cursor: pointer;
  color: white;
  transition: color 0.2s;
}
.chat-conversation-users-header span {
  display: flex;
  align-items: center;
}
.chat-conversation-users-header p {
  margin-bottom: 0;
  padding-left: 10px;
  font-weight: bold;
}
.chat-conversation-users-body {
  display: grid;
  max-height: 89%;
  overflow-y: scroll;
}
.chat-conversation-users-body button {
  border: none;
  position: relative;
  color: #313131;
  padding: 15px 0;
  padding-left: 0px;
  background: #f9f9f9;
  text-align: left;
  padding-left: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background 0.1s;
}
.chat-conversation-users-body button .chat-unread {
  width: 10px;
  height: 10px;
  position: absolute;
  background: #6bccfd;
  border-radius: 50%;
  border: 1px solid #58c0f5;
  left: 20px;
  bottom: 10px;
  box-shadow: 0px 0px 15px 2px #81b7d2;
}
.chat-conversation-users-body button.active {
  color: #fff;
  background: #218ec6 !important;
}
.chat-conversation-users-body button.active .user-chat-pic {
  border-color: white;
}
.chat-conversation-users-body button:hover {
  background: #218ec6;
  color: white;
  cursor: pointer;
  transition: background 0.1s;
}
.chat-conversation-users-body button:hover .user-chat-pic {
  border-color: white;
}
.chat-conversation-users-body button .user-chat-pic {
  height: 50px;
  width: 50px;
  border: 1px solid #313131;
  border-radius: 50%;
}
.chat-conversation-users-body button .user-chat-details {
  margin-left: 15px;
}
.chat-conversation-users-body button .exclamation-chat {
  color: #fff;
  position: absolute;
  right: 0;
  background: #e59e00;
  padding: 5px 10px 5px 20px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.chat-conversation-users-body .chat-line {
  width: 90%;
  margin: 0;
  border-width: 2px;
}

.chat-conversation-main {
  margin: 10px 30px;
  height: 500px;
  position: fixed;
  width: 350px;
  right: 350px;
  top: 0;
  z-index: 7;
}

.__talkjs_popup {
  top: 15px !important;
  right: 40px !important;
  width: 350px;
}

.show-button {
  position: fixed;
  right: 395px;
  z-index: 7;
  top: 30px;
  border: none;
}
.show-button:hover {
  cursor: pointer;
}

.modelo-tooltip {
  background: white;
}

@media (max-width: 740px) {
  .chat-popup-button {
    top: 80px;
  }

  .chat-conversation-users {
    top: 0px;
    width: 100%;
    left: 0px;
    z-index: 7;
    height: 100%;
  }

  .inbox-button {
    position: fixed;
    right: 30px !important;
  }

  .hide {
    display: none;
  }

  .chat-conversation-main {
    width: 100%;
    margin: 0px;
    height: 100%;
    left: 0px;
  }

  .show-button {
    position: fixed;
    right: 30px;
    top: 20px;
  }
}
.chat-conversation-users-body {
  overflow-y: scroll;
}

.checkbox-list-item {
  display: flex;
  padding: 0 10px;
}
.checkbox-list-item:nth-of-type(odd) {
  background: #f5f5f5;
}
.checkbox-list-item .item-checkbox {
  margin-right: 10px;
  border-right: 1px solid #eaeaea;
  padding: 20px 10px 20px 0;
}
.checkbox-list-item .item-display {
  padding: 20px 0px;
}
.checkbox-list-item .item-display p {
  margin: 0;
}

.dynamic-select-type-style {
  margin-top: 1rem;
  position: relative;
}
.dynamic-select-type-style.input-file {
  margin-top: 0;
}
.dynamic-select-type-style .input-style,
.dynamic-select-type-style .input-error {
  width: 100%;
}
.dynamic-select-type-style .input-style textarea,
.dynamic-select-type-style .input-error textarea {
  min-height: 38px;
  width: 100%;
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
  border: none;
  box-shadow: none;
  outline-offset: inherit;
  outline: none;
  resize: none;
  background: transparent;
  font-family: "Open Sans", sans-serif;
}
.dynamic-select-type-style .input-style input,
.dynamic-select-type-style .input-error input {
  min-height: 38px;
  width: 100%;
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
}
.dynamic-select-type-style .input-style input::-webkit-input-placeholder, .dynamic-select-type-style .input-error input::-webkit-input-placeholder {
  color: #808080;
}
.dynamic-select-type-style .input-style input::-ms-input-placeholder, .dynamic-select-type-style .input-error input::-ms-input-placeholder {
  color: #808080;
}
.dynamic-select-type-style .input-style input::placeholder,
.dynamic-select-type-style .input-error input::placeholder {
  color: #808080;
}
.dynamic-select-type-style .input-style input:focus,
.dynamic-select-type-style .input-error input:focus {
  box-shadow: none;
}
.dynamic-select-type-style .input-style .quill .ql-toolbar,
.dynamic-select-type-style .input-error .quill .ql-toolbar {
  border-top-color: #ff5046;
  border-right-color: #ff5046;
  border-left-color: #ff5046;
}
.dynamic-select-type-style .input-style .quill .ql-container,
.dynamic-select-type-style .input-error .quill .ql-container {
  border-left-color: #ff5046;
  border-right-color: #ff5046;
  border-bottom-color: #ff5046;
}
.dynamic-select-type-style.input-type-border .input-style {
  border: 2px solid #dddddd;
}
.dynamic-select-type-style.input-type-border .input-style textarea {
  padding: 1rem;
}
.dynamic-select-type-style.input-type-border .input-style:before {
  display: none;
}
.dynamic-select-type-style.input-type-border .input-style:hover {
  border: 2px solid #3f928b;
}
.dynamic-select-type-style.input-type-border .input-style:hover:before {
  display: none;
}
.dynamic-select-type-style.input-type-border .input-style:focus-within {
  border: 2px solid #039f97;
}
.dynamic-select-type-style.input-type-border .input-style:focus-within:after {
  display: none;
}
.dynamic-select-type-style.input-type-border .input-label-style {
  padding-bottom: 1rem;
}
.dynamic-select-type-style.input-type-border .input-error {
  border: 2px solid #ff5046;
}
.dynamic-select-type-style .quill .ql-toolbar {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.dynamic-select-type-style .quill .ql-container {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.dynamic-select-type-style .input-style,
.dynamic-select-type-style .input-error,
.dynamic-select-type-style .dropdown__control {
  border: none;
  position: relative;
}
.dynamic-select-type-style .input-style input,
.dynamic-select-type-style .input-error input,
.dynamic-select-type-style .dropdown__control input {
  border: none;
  width: 100%;
  background: transparent;
  font-family: "Open Sans", sans-serif;
}
.dynamic-select-type-style .input-style:hover:before,
.dynamic-select-type-style .input-error:hover:before,
.dynamic-select-type-style .dropdown__control:hover:before {
  border-bottom: 2px solid #3f928b;
}
.dynamic-select-type-style .input-style:focus-within:after,
.dynamic-select-type-style .input-error:focus-within:after,
.dynamic-select-type-style .dropdown__control:focus-within:after {
  transform: scaleX(1);
}
.dynamic-select-type-style .input-style:before,
.dynamic-select-type-style .input-error:before,
.dynamic-select-type-style .dropdown__control:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}
.dynamic-select-type-style .input-style:after,
.dynamic-select-type-style .input-error:after,
.dynamic-select-type-style .dropdown__control:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #039f97;
  pointer-events: none;
}
.dynamic-select-type-style .input-label-style {
  padding-bottom: 0.4rem;
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(17, 17, 17, 0.6);
  transform: translate(-10%, -55%) scale(0.9);
  font-weight: 600;
  margin-left: 4px;
}
.dynamic-select-type-style .input-label-style.text-area-label {
  font-size: 14px;
  position: relative;
  margin-left: 0;
}
.dynamic-select-type-style .dropdown__placeholder {
  margin: 0;
  margin-left: 0.1rem;
}
.dynamic-select-type-style .dropdown__value-container {
  padding: 0;
}
.dynamic-select-type-style .dropdown__indicator-separator {
  display: none;
}
.dynamic-select-type-style .dropdown--is-disabled .dropdown__control {
  background: transparent;
}
.dynamic-select-type-style .dropdown--is-disabled .dropdown__control:before {
  border-bottom-style: dotted;
}
.dynamic-select-type-style .dropdown--is-disabled + .input-label-style {
  opacity: 0.5;
}
.dynamic-select-type-style .dropdown--is-disabled .dropdown__placeholder {
  opacity: 0.5;
}
.dynamic-select-type-style .dropdown__control--is-focused {
  border: none;
  box-shadow: none;
}
.dynamic-select-type-style .dropdown__control--is-focused .dropdown__indicator-separator {
  width: 0;
}
.dynamic-select-type-style .dropdown__control--is-focused .dropdown__value-container {
  padding-left: 0;
}
.dynamic-select-type-style .dropdown__value-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: unset;
  margin-bottom: 7px;
}
.dynamic-select-type-style .dropdown__value-container .dropdown__single-value {
  transform: none;
  transform: initial;
  margin: 0;
  position: relative;
  overflow: visible;
  overflow: initial;
  max-width: none;
  max-width: initial;
  white-space: normal;
  white-space: initial;
  top: 0;
  box-sizing: inherit;
  text-overflow: initial;
  min-height: 0;
  min-height: initial;
}
.dynamic-select-type-style:focus-within .input-label-style {
  color: #039f97;
}
.dynamic-select-type-style .input-error:before {
  border-color: #ff5046;
}
.dynamic-select-type-style .input-error .dropdown__control:before {
  border-color: #ff5046;
}
.dynamic-select-type-style .input-error .dropdown__control .css-1g6gooi {
  margin: 0;
  padding: 0;
}
.dynamic-select-type-style .input-error .dropdown__menu {
  color: #313131;
}
.dynamic-select-type-style .input-error + .input-label-style {
  color: #ff5046;
}
.dynamic-select-type-style .input-error + .input-label-style + .input-helper {
  color: #ff5046;
}
.dynamic-select-type-style .input-error + .input-helper {
  color: #ff5046;
}
.dynamic-select-type-style .input-helper {
  font-size: 12px;
  color: #585858;
}

.login-background {
  display: flex;
  display: -ms-flexbox;
  min-width: 0;
  min-height: 0;
  flex: 1 1 auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  position: fixed;
  background-image: linear-gradient(#039f97, #fff);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}
.login-background .login-card {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 17, 0.1);
  width: 32rem;
  margin: auto;
  box-shadow: 2px 2px 10px -3px rgba(0, 0, 0, 0.75);
}
.login-background .login-card .login-spacing {
  padding: 3rem;
}
.login-background .login-card .login-title {
  font-weight: 200;
  font-size: 2rem;
}
.login-background .login-logo {
  max-width: 250px;
}

.login-error {
  background: #e6646e;
  color: #fff;
  min-width: 288px;
  max-width: 568px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.login-error .icon:hover {
  background: #ff5046;
  opacity: 0.5;
}

.manutencao-card {
  padding: 1rem;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: rgba(17, 17, 17, 0.1);
  width: 50rem;
  margin: auto;
}

@media (max-width: 480px) {
  .login-background {
    padding: 0;
  }

  .login-spacing {
    padding: 3rem 1.5rem !important;
  }

  .login-logo {
    width: 200px;
  }
}
.not-found {
  text-align: center;
}

.punch-card-print-table {
  margin: 10px 20px;
  font-size: 10px;
  font-family: arial;
  width: 95%;
}
.punch-card-print-table thead tr .header {
  text-align: center;
}
.punch-card-print-table thead tr .header .title {
  font-size: 18px;
}
.punch-card-print-table thead tr .header .date-range {
  font-size: 14px;
}
.punch-card-print-table thead tr td .teacher {
  font-size: 14px;
  font-weight: bold;
}
.punch-card-print-table thead tr.columns {
  background: #eee;
}
.punch-card-print-table thead tr.columns th {
  border: 1px solid black;
  box-sizing: content-box;
}
.punch-card-print-table tbody tr {
  line-height: 1.3;
  background-color: #f9f9f9;
}
.punch-card-print-table tbody tr td {
  text-align: center;
  height: 60px;
  box-sizing: content-box;
}
.punch-card-print-table hr {
  border: 1px solid #000;
  margin: 1px;
}
.punch-card-print-table tfoot tr .current-time {
  text-align: right;
  font-size: 13px;
  margin-left: 15px;
}
.punch-card-print-table tfoot tr .place {
  text-align: center;
}
.punch-card-print-table tfoot tr .place .place-name {
  display: block;
}

.detailed-punch-card-print-table {
  margin: 10px 20px;
  font-size: 10px;
  font-family: arial;
  width: 95%;
}
.detailed-punch-card-print-table thead tr .header {
  text-align: center;
}
.detailed-punch-card-print-table thead tr .header .title {
  font-size: 18px;
}
.detailed-punch-card-print-table thead tr .header .date-range {
  font-size: 14px;
}
.detailed-punch-card-print-table thead tr td .teacher {
  font-size: 14px;
  font-weight: bold;
}
.detailed-punch-card-print-table thead tr.columns {
  background: #eee;
}
.detailed-punch-card-print-table thead tr.columns th {
  border: 1px solid black;
  box-sizing: content-box;
}
.detailed-punch-card-print-table tbody tr {
  line-height: 1.3;
  background-color: #f9f9f9;
}
.detailed-punch-card-print-table tbody tr td {
  text-align: center;
  height: 60px;
  box-sizing: content-box;
}
.detailed-punch-card-print-table hr {
  border: 1px solid #000;
  margin: 1px;
}

.turma_disciplinas_container {
  width: 100%;
  height: 90%;
  padding: 15px;
  border: 5px solid #f5f5f5;
  border-radius: 10px;
  box-shadow: 11px 11px 22px #e6e6e6, -11px -11px 22px #ffffff;
  display: "flex";
  flex-direction: "row";
  justify-content: "space-between";
  align-items: "center";
  margin-bottom: 20px;
}
.turma_disciplinas_container .button-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body .p-dialog .p-dialog-footer {
  border: none;
}

/*# sourceMappingURL=main.css.map */

